import { ListItemIcon, Snackbar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import {
  Assignment,
  AttachMoney,
  EventAvailable,
  EventNote,
  Face,
  LiveTv,
  Person,
  QuestionAnswer,
  Schedule,
  VideoLibrary,
} from "@material-ui/icons";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Lock from "@material-ui/icons/Lock";
import AccountCircleImage from "assets/img/AccountCircleImage2.png";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx";
import ClassNames from "classnames";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import { logoutUser } from "../apis/UserService.js";
import Header from "../components/Header/Header.jsx";
import LinkHome from "../components/Header/LinkHome.jsx";
import NotificationTab from "../mycomponents/inAppNotification/Index";
import RedirectUnpaidPublicUsers from "../utils/RedirectUnpaidPublicUsers.js";
import paymentService from "../utils/paymentService.js";
import AlertComponent from "./AlertComponent.jsx";
import ParentHeaders from "./ParentHeaders.js";
import PasswordChange from "./PasswordChange.js";
import PaymentPopup from "./components/PaymentPopup.jsx";
import clearLocalStorage from "../common/clearStore.js";

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  selected: {},
})(MuiListItem);
const styles = (theme) => ({
  root: {
    // background: '#46abe3'
    color: "transparent",
    height: "45px",
    background: "#EFF2F4 !important",
    boxShadow: "-8px -8px 20px #FFFFFF, 8px 8px 20px #D1D9E6",
    // borderRadius: "197px",
    width: "45px",
    borderRadius: "50%",
    padding: "0",
    margin: "0",
  },
  img: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    padding: "0",
    margin: "0",
  },
  appBar: {
    color: "white",
    // background: '#54bdd5',
    marginBottom: theme.spacing.unit * 6,
  },
  drawItem: {
    color: "grey",
    padding: theme.spacing.unit * 1,
  },
  onesazNav: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "35px",
    color: "#31456ac2",
    borderBottom: "2px",
    "&:hover, &:focus": {
      color: "#31456A",
    },
    [theme.breakpoints.down("sm")]: {
      display: "contents",
    },
  },
  onactive: {
    fontWeight: "700",
    color: "#31456A",
    borderBottom: "2px solid #08C5A7",
  },
});

const MyHeader = (props) => {
  let user = store.get("user") ? store.get("user") : {};
  const startedExam = store.get("startedExam");
  const accountType = user.accountType;
  let mockUser = true;
  mockUser = user._id && user._id.includes("mocktest_");
  // referral link to be copied to clipboard
  const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [referralMessage, setReferralMessage] = useState(
    `Use my referral link to sign up on Onesaz.com. ${
      window.location.origin
    }/register?referralCode=fake_code`
  );
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertText, setAlertText] = useState("");

  const [snackBarState, setSnackBarState] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openSnackBar } = snackBarState;
  const anchorRef = React.createRef();
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    setAlertText("");
  };
  const { classes } = props;

  function handleOpen() {
    setOpen(true);
  }
  const handleClosePaymentBox = () => {
    setPaymentOpen(false);
  };
  function handleClose(event) {
    console.log(anchorRef, open);
    // console.log(anchorRef.current,event.target)
    //    if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }
    setOpen(false);
  }
  // referral link controller
  const handleReferralLink = (newSnackBarState) => {
    setSnackBarState({ openSnackBar: true, ...newSnackBarState });
    navigator.clipboard.writeText(referralMessage);
  };
  const handleCloseSnackBar = () => {
    setSnackBarState({ ...snackBarState, openSnackBar: false });
  };
  /////////////////////////////
  async function logout() {
    const exam = store.get("startedExam");
    if (exam && exam.test_status === "STARTED") {
      setConfirmOpen(true);
      setAlertText(
        `Please, Submit Test : ${exam.testDisplayName} Before Logging Out!`
      );
    } else {
      handleClose("");
      const user = store.get("user");
      const _id = user ? user._id : "";
      const phase = user ? user.phase : "";
      await logoutUser({ id: _id });
      clearLocalStorage();
      if (phase && phase === "enquiry") {
        props.history.push("/reg-login");
      } else {
        props.history.push("/sign-in");
      }
    }
  }

  const handleUnpaidPublicUser = () => {
    if (RedirectUnpaidPublicUsers()) {
      setPaymentOpen(true);
    } else {
      setPaymentOpen(false);
    }
  };

  const { hostname } = window.location;
  return (
    <Grid
      container
      className={classes.appBar}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Header
        color="info"
        brand="Onesaz"
        links={
          accountType && accountType === "parent" ? (
            ParentHeaders(classes, user, handleOpen, logout)
          ) : (
            <React.Fragment>
              <Hidden mdUp>
                <List className={classes.list + " " + classes.mlAuto}>
                  {user._id !== null && !user._id.includes("mocktest_") ? (
                    <React.Fragment>
                      {store.get("user").statusType === "unpaid" &&
                      (hostname === "onesaz.com" ||
                        hostname === "www.onesaz.com") ? (
                        <ListItem
                          selected={window.location.pathname == "/plans"}
                          className={classes.drawItem}
                          button
                        >
                          <Link to="/plans" className={classes.onesazNav}>
                            <ListItemIcon>
                              <AttachMoney />
                            </ListItemIcon>
                            Plans & Pricing
                          </Link>
                          &nbsp;&nbsp;&nbsp;
                        </ListItem>
                      ) : null}
                      {store.get("user").institute ==
                        "5d679d49c136660a09596d85" && (
                        <ListItem
                          selected={window.location.pathname == "/queries"}
                          className={classes.drawItem}
                          button
                        >
                          <Link to="/queries" className={classes.onesazNav}>
                            <ListItemIcon>
                              <QuestionAnswer />
                            </ListItemIcon>
                            Queries
                          </Link>
                          &nbsp;&nbsp;&nbsp;
                        </ListItem>
                      )}
                      {/* <ListItem
                        selected={window.location.pathname == "/dashboard"}
                        className={classes.drawItem}
                        button
                      >
                        <Link to="/dashboard" className={classes.onesazNav}>
                          <ListItemIcon>
                            <EventAvailable />
                          </ListItemIcon>
                          Dash Board
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                      </ListItem> */}
                      <ListItem
                        selected={window.location.pathname == "/home"}
                        className={classes.drawItem}
                        button
                      >
                        <Link to="/home" className={classes.onesazNav}>
                          <ListItemIcon>
                            <EventAvailable />
                          </ListItemIcon>
                          Exams
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                      </ListItem>
                      {/* -------------------Attendance--------------- */}
                      <ListItem
                        selected={window.location.pathname == "/attendance"}
                        className={classes.drawItem}
                        button
                      >
                        <Link to="/attendance" className={classes.onesazNav}>
                          <ListItemIcon>
                            <EventAvailable />
                          </ListItemIcon>
                          Attendance
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                      </ListItem>
                      {/* practice route tab  */}
                      {([
                        "5d679d49c136660a09596d85",
                        "5ee85fc60f25a93146ea0n4o",
                      ].includes(user.institute) ||
                        store.get("user").userId === "sudhir") && (
                        <ListItem
                          selected={window.location.pathname == "/practice"}
                          className={classes.drawItem}
                          button
                        >
                          <Link to="/practice" className={classes.onesazNav}>
                            <ListItemIcon>
                              <EventNote />
                            </ListItemIcon>
                            Practice
                          </Link>
                          &nbsp;&nbsp;&nbsp;
                        </ListItem>
                      )}
                      {/* {user &&
                      user.institute === "5e7370083d8520a40c4ceaaa" &&
                      user.level != "2119611" ? (
                        <ListItem
                          selected={window.location.pathname == "/doubts"}
                          className={classes.drawItem}
                          button
                        >
                          <Link to="/doubts" className={classes.onesazNav}>
                            <ListItemIcon>
                              <Group />
                            </ListItemIcon>
                            Doubts
                          </Link>
                          &nbsp;&nbsp;&nbsp;
                        </ListItem>
                      ) : null} */}
                      <ListItem
                        selected={window.location.pathname == "/video-classes"}
                        className={classes.drawItem}
                        button
                      >
                        {/* ||
                       user.institute=="5d72f343059f3004e1ceaa81" */}

                        {user && user.level === "2119611" ? null : user &&
                          user.institute == "5eb51918221f8d333f7457cd" ? (
                          <Link
                            to="/interactive-classes"
                            className={classes.onesazNav}
                          >
                            <ListItemIcon>
                              <LiveTv />
                            </ListItemIcon>
                            Live Classes
                          </Link>
                        ) : (
                          <Link
                            to="/video-classes"
                            className={classes.onesazNav}
                          >
                            <ListItemIcon>
                              <LiveTv />
                            </ListItemIcon>
                            Live Classes
                          </Link>
                        )}

                        {/* <Link to="/worksheets">Work Sheets</Link> */}
                      </ListItem>

                      {user &&
                      user.institute == "5e9098aaa218252d49be5a3b" &&
                      user.level == "191813" ? (
                        <ListItem className={classes.drawItem} button>
                          <a
                            href="https://onesaz-new.s3.ap-south-1.amazonaws.com/schedules/Out-going+Sr.Titan+exams+schedule.pdf"
                            target="__blank"
                            className={classes.onesazNav}
                          >
                            <ListItemIcon>
                              <Schedule />
                            </ListItemIcon>
                            Exam-schedule
                          </a>
                        </ListItem>
                      ) : null}

                      {/* <Link to="/worksheets">Work Sheets</Link> */}

                      {user &&
                      user.institute !== "5eb51918221f8d333f7457cd" &&
                      user.institute !== "5e7370083d8520a40c4ceaaa" &&
                      user.level != "2119611" ? (
                        <ListItem
                          selected={
                            window.location.pathname == "/previous-classes"
                          }
                          className={classes.drawItem}
                          button
                        >
                          <Link
                            to="/previous-classes"
                            className={classes.onesazNav}
                          >
                            <ListItemIcon>
                              <VideoLibrary />
                            </ListItemIcon>
                            Previous Classes
                          </Link>
                          {/* <Link to="/worksheets">Work Sheets</Link>  */}
                        </ListItem>
                      ) : null}
                      {user && user.level == "2119611" ? null : (
                        <ListItem
                          disabled={startedExam}
                          selected={window.location.pathname == "/assignment"}
                          className={classes.drawItem}
                          button
                        >
                          <Link
                            to={startedExam ? "/home" : "/assignment"}
                            className={classes.onesazNav}
                          >
                            <ListItemIcon>
                              <Assignment />
                            </ListItemIcon>
                            Library
                          </Link>
                          &nbsp;&nbsp;&nbsp;
                        </ListItem>
                      )}

                      <ListItem disabled className={classes.drawItem}>
                        <ListItemIcon>
                          <Person />
                        </ListItemIcon>
                        {store.get("user") ? store.get("user").loginIndex : ""}

                        {/* <Link to="/worksheets">Work Sheets</Link>  */}
                      </ListItem>
                      <ListItem
                        selected={window.location.pathname == "/profile"}
                        className={classes.drawItem}
                        button
                      >
                        <Link to="/profile" className={classes.onesazNav}>
                          <ListItemIcon>
                            <Face />
                          </ListItemIcon>
                          Profile
                        </Link>
                      </ListItem>
                      {/* referral link button  */}
                      {user && user.institute === "5d679d49c136660a09596d85" ? (
                        <ListItem className={classes.drawItem}>
                          <Button
                            onClick={() => {
                              handleReferralLink({
                                vertical: "top",
                                horizontal: "right",
                              });
                            }}
                            color="primary"
                            size="sm"
                            startIcon={
                              <FileCopyOutlinedIcon fontSize="inherit" />
                            }
                          >
                            Refer to friends
                          </Button>
                        </ListItem>
                      ) : null}
                      {user.institute === "5fd25b12ed14200ff702f738" ||
                      user.institute === "5e7370083d8520a40c4ceaaa" ? null : (
                        <ListItem button className={classes.drawItem}>
                          <span
                            className={classes.onesazNav}
                            onClick={handleOpen}
                          >
                            <ListItemIcon>
                              <Lock />
                            </ListItemIcon>
                            Change Password
                          </span>
                        </ListItem>
                      )}

                      {window.location.hostname != "localhost" && (
                        <ListItem button className={classes.drawItem}>
                          <Button onClick={logout}>
                            Logout &nbsp; <LogoutIcon />
                          </Button>
                        </ListItem>
                      )}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </List>
              </Hidden>

              <LinkHome dropdownHoverColor="info" />
              <Hidden smDown>
                <React.Fragment>
                  {store.get("user").statusType === "unpaid" &&
                  (hostname === "onesaz.com" ||
                    hostname === "www.onesaz.com") ? (
                    <Link
                      to="/plans"
                      className={ClassNames(
                        classes.onesazNav,
                        window.location.pathname == "/plans"
                          ? classes.onactive
                          : ""
                      )}
                    >
                      Plans&Pricing
                    </Link>
                  ) : null}
                  {/* {store.get("user").institute ==
                  "5d679d49c136660a09596d85" && (
                    <Link
                      to="/queries"
                      className={ClassNames(
                        classes.onesazNav,
                        window.location.pathname == "/queries"
                          ? classes.onactive
                          : ""
                      )}
                    >
                      Queries
                    </Link>
                  )} */}
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp; */}
                  {/* <Link
                    to="/dashboard"
                    className={ClassNames(
                      classes.onesazNav,
                      window.location.pathname == "/dashboard"
                        ? classes.onactive
                        : ""
                    )}
                  >
                    DashBoard
                  </Link> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link
                    to="/home"
                    className={ClassNames(
                      classes.onesazNav,
                      window.location.pathname == "/home"
                        ? classes.onactive
                        : ""
                    )}
                  >
                    Exams
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link
                    to="/attendance"
                    className={ClassNames(
                      classes.onesazNav,
                      window.location.pathname == "/attendance"
                        ? classes.onactive
                        : ""
                    )}
                  >
                    Attendance
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* practice route desktop  */}
                  {[
                    "5d679d49c136660a09596d85",
                    "5ee85fc60f25a93146ea0n4o",
                  ].includes(user.institute) && (
                    <Link
                      to="/practice"
                      onClick={handleUnpaidPublicUser}
                      className={ClassNames(
                        classes.onesazNav,
                        window.location.pathname == "/practice"
                          ? classes.onactive
                          : ""
                      )}
                    >
                      Practice
                    </Link>
                  )}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* {user &&
                    user.institute === "5e7370083d8520a40c4ceaaa" &&
                    user.level != "2119611" && (
                      <Link
                        to="/doubts"
                        className={ClassNames(
                          classes.onesazNav,
                          window.location.pathname == "/doubts"
                            ? classes.onactive
                            : ""
                        )}
                      >
                        Doubts
                      </Link>
                    )} */}
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                  {/* <Link to="/worksheets">Practice Problems</Link>&nbsp;&nbsp;&nbsp; */}
                  {user.level == "2119611" ? null : user &&
                    user.institute == "5eb51918221f8d333f7457cd" ? (
                    <Link
                      to="/interactive-classes"
                      className={ClassNames(
                        classes.onesazNav,
                        window.location.pathname == "/interactive-classes"
                          ? classes.onactive
                          : ""
                      )}
                    >
                      Live
                    </Link>
                  ) : (
                    <Link
                      to={
                        user.institute === "5d679d49c136660a09596d85" &&
                        user.statusType === "unpaid"
                          ? null
                          : "/video-classes"
                      }
                      onClick={handleUnpaidPublicUser}
                      className={ClassNames(
                        classes.onesazNav,
                        window.location.pathname == "/video-classes"
                          ? classes.onactive
                          : ""
                      )}
                    >
                      Live
                    </Link>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {user.institute == "5e9098aaa218252d49be5a3b" &&
                  user.level == "191813" ? (
                    <a
                      href="https://onesaz-new.s3.ap-south-1.amazonaws.com/schedules/Out-going+Sr.Titan+exams+schedule.pdf"
                      target="__blank"
                      className={ClassNames(classes.onesazNav)}
                    >
                      Exam-schedule
                    </a>
                  ) : null}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {user.institute !== "5eb51918221f8d333f7457cd" &&
                  user.institute !== "5e7370083d8520a40c4ceaaa" &&
                  user.level != "2119611" ? (
                    <Link
                      to={
                        user.institute === "5d679d49c136660a09596d85" &&
                        user.statusType === "unpaid"
                          ? null
                          : "/previous-classes"
                      }
                      onClick={handleUnpaidPublicUser}
                      className={ClassNames(
                        classes.onesazNav,
                        window.location.pathname == "/previous-classes"
                          ? classes.onactive
                          : ""
                      )}
                    >
                      Previous
                    </Link>
                  ) : null}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {user && user.level == "2119611" ? null : (
                    <Link
                      to={startedExam ? "/home" : "/assignment"}
                      className={ClassNames(
                        classes.onesazNav,
                        window.location.pathname == "/assignment"
                          ? classes.onactive
                          : ""
                      )}
                    >
                      Library
                    </Link>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* <Link
                    to="/make-payment"
                    className={ClassNames(
                      classes.onesazNav,
                      window.location.pathname == "/make-payment"
                        ? classes.onactive
                        : ""
                    )}
                  >
                    Fee
                  </Link> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* ----------------Notification tab--------------------- -*/}
                  <NotificationTab />
                  &nbsp;
                  {/* ------------------------------------------------------- */}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {/* 
                   <Button onClick={logout}>
                    <LogoutIcon></LogoutIcon>
                  </Button> */}
                  <CustomDropdown
                    left
                    caret={false}
                    hoverColor="transparent"
                    dropdownHeader={
                      store.get("user") ? store.get("user").name : ""
                    }
                    buttonText={
                      <img
                        src={AccountCircleImage}
                        className={classes.img}
                        alt="profile"
                      />
                    }
                    buttonProps={{
                      className:
                        classes.navLink +
                        " " +
                        classes.imageDropdownButton +
                        " " +
                        classes.root,
                    }}
                    dropdownList={[
                      user.institute === "5fd25b12ed14200ff702f738" ||
                      user.institute === "5e7370083d8520a40c4ceaaa" ? null : (
                        <span onClick={handleOpen}>change password</span>
                      ),
                      // referral link button
                      user && user.institute === "5d679d49c136660a09596d85" ? (
                        <Button
                          onClick={() => {
                            handleReferralLink({
                              vertical: "top",
                              horizontal: "right",
                            });
                          }}
                          color="primary"
                          startIcon={
                            <FileCopyOutlinedIcon fontSize="inherit" />
                          }
                        >
                          Refer to friends
                        </Button>
                      ) : null,
                      <Fragment>
                        {" "}
                        {window.location.hostname != "localhost" && (
                          <span onClick={logout}>
                            sign out
                            <LogoutIcon
                              style={{
                                marginLeft: "4px",
                                height: "18px",
                                verticalAlign: "bottom",
                              }}
                            />
                          </span>
                        )}
                      </Fragment>,
                    ]}
                  />
                </React.Fragment>
              </Hidden>
            </React.Fragment>
          )
        }
        fixed
      />
      {/* LOGIN MODAL START */}
      <PasswordChange open={open} handleClose={handleClose} />
      {/* LOGIN MODAL END */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackBar}
        onClose={handleCloseSnackBar}
        message={referralMessage && "Link copied to clipboard!"}
        key={vertical + horizontal}
      />
      {/* PAYMENT MODAL */}
      <PaymentPopup
        open={paymentOpen}
        handleClose={handleClosePaymentBox}
        onClick={() => {
          setPaymentOpen(false);
          paymentService("MAINS2022");
        }}
      />
      <AlertComponent open={confirmOpen} closeAlert={handleCloseAlertBox}>
        {alertText}
      </AlertComponent>
    </Grid>
  );
};

export default compose(
  withRouter,
  withStyles(styles, headerLinksStyle, javascriptStyles)
)(MyHeader);
