import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Grid,
  Typography,
  Box,
  Paper,
  Chip,
  IconButton,
  Collapse,
  makeStyles,
} from "@material-ui/core";
import {
  NotificationsOutlined,
  Schedule,
  ExpandMore,
  ExpandLess,
  School,
  AttachMoney,
  DirectionsBus,
  Info,
  LocationOn,
} from "@material-ui/icons";
import useFetch from "../../../hooks/useFetch";
import { getParentConcerns } from "../../../apis/parentConcerns";
import Header from "./Components/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
    maxWidth: 600,
    margin: "0 auto",
    minHeight: "100vh",
  },
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tabs: {
    minHeight: 48,
    "& .MuiTab-root": {
      minHeight: 48,
      fontSize: 14,
      fontWeight: 600,
      // minWidth: 120,
      minWidth: 100,
      padding: theme.spacing(1),
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      "& > *:first-child": {
        fontSize: 18,
        marginBottom: 0,
        marginRight: theme.spacing(1),
      },
    },
  },
  notification: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
    // borderRadius: theme.spacing(2),
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[3],
    },
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  statusSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: theme.spacing(1),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  date: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    // marginTop: theme.spacing(2),
    lineHeight: 1.6,
  },
  chip: {
    height: 28,
    fontSize: 12,
    fontWeight: 600,
    padding: theme.spacing(0, 2),
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  chipWarning: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  chipError: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
  transportInfo: {
    // marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1.5),
    justifyContent: "space-between",
  },
  transportDetail: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    // marginTop: theme.spacing(1),
  },
  transportLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  transportValue: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  metadata: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // marginTop: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: 13,
      fontWeight: 2 * 500,
      color: theme.palette.text.secondary,
    },
  },
  categoryIcon: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
  nodata: {
    margin: theme.spacing(4),
    textAlign: "center",
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  valuemain: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
}));
const TABS_CONFIG = [
  { label: "All", type: "All", icon: <NotificationsOutlined /> },
  { label: "Academic", type: "ACADEMIC_PARENT_CONCERN", icon: <School /> },
  { label: "General", type: "GENERAL_PARENT_CONCERN", icon: <Info /> },
  { label: "Fee", type: "FEE_PARENT_CONCERN", icon: <AttachMoney /> },
  {
    label: "Transport",
    type: "TRANSPORT_PARENT_CONCERN",
    icon: <DirectionsBus />,
  },
];
const NotificationCard = ({ concern }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const getStatusChipClass = (status) => {
    switch (status) {
      case "Open":
        return classes.chipWarning;
      case "APPROVED":
        return classes.chipSuccess;
      case "REJECTED":
        return classes.chipError;
      default:
        return "";
    }
  };
  const getConcernIcon = (type) => {
    switch (type) {
      case "ACADEMIC_PARENT_CONCERN":
        return <School />;
      case "FEE_PARENT_CONCERN":
        return <AttachMoney />;
      case "TRANSPORT_PARENT_CONCERN":
        return <DirectionsBus />;
      default:
        return <Info />;
    }
  };
  const renderTransportInfo = () => {
    if (concern.requestType !== "TRANSPORT_PARENT_CONCERN") {
      return null;
    }
    const transportDetails =
      concern.transportDetails || concern.description || {};
    const {
      category,
      pickupType,
      availDate,
      address,
      landmark,
      pickupPlace,
      dropPlace,
    } = transportDetails;

    return (
      <Collapse in={expanded}>
        <Box className={classes.transportInfo}>
          <Grid container spacing={2}>
            {/* Location Grid - Full width in first row */}
            {address && (
              <Grid item xs={12}>
                <Box className={classes.transportDetail}>
                  <Typography className={classes.transportLabel}>
                    Location
                  </Typography>
                  <Typography className={classes.valuemain}>
                    {address}
                  </Typography>
                  {landmark && (
                    <div>
                      <Typography className={classes.transportLabel}>
                        Landmark:
                      </Typography>
                      <Typography>{landmark}</Typography>
                    </div>
                  )}
                </Box>
              </Grid>
            )}

            {/* Transport Details Grid - Half width in second row */}
            {category && (
              <Grid item xs={12} md={6}>
                <Box className={classes.transportDetail}>
                  <Typography className={classes.transportLabel}>
                    Transport Details
                  </Typography>
                  <Typography className={classes.transportValue}>
                    {category}
                  </Typography>
                  {availDate && (
                    <Typography className={classes.transportValue}>
                      Available From:
                      <span className={classes.valuemain}> {availDate}</span>
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}

            {/* Route Grid - Half width in second row */}
            {(pickupPlace || dropPlace) && (
              <Grid item xs={12} md={6}>
                <Box className={classes.transportDetail}>
                  <Typography className={classes.transportLabel}>
                    Route
                  </Typography>
                  <Box>
                    <Typography className={classes.transportValue}>
                      {pickupType}
                      {""} Pickup
                    </Typography>
                  </Box>
                  {pickupPlace && (
                    <Typography className={classes.transportValue}>
                      Pickup:
                      <span className={classes.valuemain}> {pickupPlace}</span>
                    </Typography>
                  )}
                  {dropPlace && (
                    <Typography className={classes.transportValue}>
                      Drop:
                      <span className={classes.valuemain}> {dropPlace}</span>
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Collapse>
    );
  };

  return (
    <Paper elevation={1} className={classes.notification}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <div className={classes.categoryIcon}>
            {getConcernIcon(concern.requestType)}
          </div>
          <Typography className={classes.title}>
            {concern.requestType
              .split("_")
              .slice(0, -2)
              .join(" ")}
          </Typography>
        </div>
        <div className={classes.statusSection}>
          <Chip
            label={concern.status}
            size="small"
            className={`${classes.chip} ${getStatusChipClass(concern.status)}`}
          />
          <Typography variant="body2" className={classes.date}>
            <Schedule fontSize="small" />
            {new Date(concern.createdAt).toLocaleDateString()}
          </Typography>
        </div>
      </div>

      <div className={classes.metadata}>
        <Typography>
          {concern.academicYear} • {concern.admissionNo}
        </Typography>
        <IconButton size="small" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>

      {renderTransportInfo()}

      <Collapse in={expanded}>
        <div className={classes.contentArea}>
          <Typography className={classes.description}>
            {concern.description.message || concern.description}
          </Typography>
          {concern.remark && (
            <Typography className={classes.descriptionMessage}>
              <strong>Remark:</strong> {concern.remark}
            </Typography>
          )}
        </div>
      </Collapse>
    </Paper>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes.tabPanel}>{children}</Box>}
    </div>
  );
};

const LastConcern = () => {
  const [loading, error, response, fetch] = useFetch(getParentConcerns);
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    fetch();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error)
    return <Typography color="error">Error: {error.message}</Typography>;
  if (!response || response.length === 0)
    return (
      <Grid>
        <Typography className={classes.nodata}>
          No concerns available.
        </Typography>
      </Grid>
    );

  const sortedConcerns = response.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  return (
    <div className={classes.root}>
      <Header title="Previous Concerns" path="/parentconcern" />
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="concern categories"
          variant="scrollable"
          indicatorColor="primary"
          scrollButtons="none"
          textColor="primary"
          className={classes.tabs}
        >
          {TABS_CONFIG.map((tab, index) => (
            <Tab key={index} label={tab.label} icon={tab.icon} />
          ))}
        </Tabs>
      </AppBar>

      {TABS_CONFIG.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          <Grid container spacing={1}>
            {(tab.type === "All"
              ? sortedConcerns
              : sortedConcerns.filter((c) => c.requestType === tab.type)
            ).map((concern, idx) => (
              <Grid item xs={12} key={idx}>
                <NotificationCard concern={concern} />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      ))}
    </div>
  );
};

export default LastConcern;
