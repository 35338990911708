const allSubMap = {
  M: {
    subject: "MATHEMATICS",
    correct: "MC",
    wrong: "MW",
    subjectCode: "M",
    unAttempted: "MU",
  },
  P: {
    subject: "PHYSICS",
    correct: "PC",
    wrong: "PW",
    subjectCode: "P",
    unAttempted: "PU",
  },
  C: {
    subject: "CHEMISTRY",
    correct: "CC",
    wrong: "CW",
    subjectCode: "C",
    unAttempted: "CU",
  },
  B: {
    subject: "BOTANY",
    correct: "BC",
    wrong: "BW",
    subjectCode: "B",
    unAttempted: "BU",
  },
  Z: {
    subject: "ZOOLOGY",
    correct: "ZC",
    wrong: "ZW",
    subjectCode: "Z",
    unAttempted: "ZU",
  },
  BIO: {
    subject: "BIOLOGY",
    correct: "BIOC",
    wrong: "BIOW",
    subjectCode: "BIO",
    unAttempted: "BIOU",
  },
  S: {
    subject: "SANSKRIT",
    correct: "SC",
    wrong: "SW",
    subjectCode: "S",
    unAttempted: "SU",
  },
  TEL: {
    subject: "TELUGU",
    correct: "TELC",
    wrong: "TELW",
    subjectCode: "TEL",
    unAttempted: "TELU",
  },
  H: {
    subject: "HINDI",
    correct: "HC",
    wrong: "HW",
    subjectCode: "H",
    unAttempted: "HU",
  },
  E: {
    subject: "ENGLISH",
    correct: "EC",
    wrong: "EW",
    subjectCode: "E",
    unAttempted: "EU",
  },
  PSC: {
    subject: "PHYSICAL_SCIENCE",
    correct: "PSCC",
    wrong: "PSCW",
    subjectCode: "PSC",
    unAttempted: "PSCU",
  },
  GSC: {
    subject: "GENERAL_SCIENCE",
    correct: "GSCC",
    wrong: "GSCW",
    subjectCode: "GSC",
    unAttempted: "GSCU",
  },
  GSC: {
    subject: "SCIENCE",
    correct: "GSCC",
    wrong: "GSCW",
    subjectCode: "GSC",
    unAttempted: "GSCU",
  },
  SOC: {
    subject: "SOCIAL",
    correct: "SOCC",
    wrong: "SOCW",
    subjectCode: "SOC",
    unAttempted: "SOCU",
  },
  CIV: {
    subject: "CIVICS",
    correct: "CIVC",
    wrong: "CIVW",
    subjectCode: "CIV",
    unAttempted: "CIVU",
  },
  ECO: {
    subject: "ECONOMICS",
    correct: "ECOC",
    wrong: "ECOW",
    subjectCode: "ECO",
    unAttempted: "ECOU",
  },
  COM: {
    subject: "COMMERCE",
    correct: "COMC",
    wrong: "COMW",
    subjectCode: "COM",
    unAttempted: "COMU",
  },
  MED: {
    subject: "MEDIAIMS",
    correct: "MEDC",
    wrong: "MEDW",
    subjectCode: "MED",
    unAttempted: "MEDU",
  },
  ACCOUNTS: {
    subject: "ACCOUNTS",
    correct: "ACCC",
    wrong: "ACCW",
    subjectCode: "ACC",
    unAttempted: "ACCU",
  },
  SCIENCE: {
    subject: "SCIENCE",
    correct: "SCC",
    wrong: "SCW",
    subjectCode: "SC",
    unAttempted: "SCU",
  },
};
export default allSubMap;
