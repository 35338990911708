import React, { Component } from "react";
import YoutubeControllers from "./YoutubeControllers";
import "./IframePlayer.css";
import { saveYTAnalytics } from "../../apis/videoService";
import store from "store";

let LoadYouTube;

class YoutubePLayer extends Component {
  state = {
    playerState: {},
    playerStateChange: {},
    watchTime: 0, // Watch time in seconds
    joinTime: null, // When the user starts watching
    leaveTime: null, // When the user stops watching
  };

  interval = null;
  isTabVisible = true;

  componentDidMount() {
    document.getElementById("yt").addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    // Listen for tab visibility changes
    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    if (!LoadYouTube) {
      LoadYouTube = new Promise((resolve) => {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = () => resolve(window.YT);
      });
    }
    LoadYouTube.then((YT) => {
      this.player = new YT.Player(this.youtubePlayerAnchor, {
        height: this.props.height || 390,
        width: this.props.width || 640,
        videoId: this.props.YTid,
        playerVars: {
          autoplay: 0,
          controls: 0,
          modestbranding: 1,
          enablejsapi: 1,
          rel: 0,
          showinfo: 0,
        },
        events: {
          onStateChange: this.onPlayerStateChange,
          onReady: this.onPlayerReady,
          onPlaybackQualityChange: this.onPlaybackQualityChange,
        },
        origin: `${window.location.origin}`,
      });
    });
  }

  componentWillUnmount() {
    this.stopWatchTimer();
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    this.sendWatchTimeToServer(); // Send data on unmount
  }

  handleVisibilityChange = () => {
    this.isTabVisible = !document.hidden;
    console.log("Tab visibility changed:", this.isTabVisible);
    if (
      this.player &&
      this.player.getPlayerState &&
      this.player.getPlayerState() === 1
    ) {
      if (this.isTabVisible) {
        this.startWatchTimer();
      } else {
        this.stopWatchTimer();
      }
    }
  };

  startWatchTimer = () => {
    if (!this.interval) {
      console.log("Starting watch timer...");
      this.interval = setInterval(() => {
        this.setState((prevState) => ({
          watchTime: prevState.watchTime + 1,
        }));
      }, 1000);
    }
  };

  stopWatchTimer = () => {
    if (this.interval) {
      console.log("Stopping watch timer...");
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  sendWatchTimeToServer = () => {
    const { watchTime, joinTime, leaveTime } = this.state;
    const { YTid } = this.props;
    const {
      userId,
      fullName,
      name,
      level,
      branchId,
      sectionid,
      batch,
      branch,
      section,
    } = store.get("user") || {};
    if (watchTime > 0 && userId && YTid) {
      const payload = {
        userId,
        userName: name || fullName,
        batch: level || batch,
        branch: branchId || branch,
        section: sectionid || section,
        videoId: YTid,
        watchTime,
        joinTime,
        leaveTime: leaveTime || new Date().toISOString(), // Default to current time if not set
      };
      console.log("Payload to send:", payload);
      saveYTAnalytics(payload)
        .then((res) => res.json())
        .then((response) => {
          console.log("Watch time saved:", response);
        })
        .catch((err) => {
          console.error("Error saving watch time:", err);
        });
    } else {
      console.warn("Watch time not sent. Missing required data.");
    }
  };

  onPlayerStateChange = (e) => {
    const playerState = e.data;
    console.log("Player state changed:", playerState);
    this.setState({ playerStateChange: e.target });

    switch (playerState) {
      case 1: // playing
        if (!this.state.joinTime) {
          this.setState({ joinTime: new Date().toISOString() }); // Set join time
          console.log("Join time set:", this.state.joinTime);
        }
        if (this.isTabVisible) this.startWatchTimer();
        break;
      case 2: // paused
      case 3: // buffering
      case 5: // cued
        this.setState({ leaveTime: new Date().toISOString() }); // Set leave time
        console.log("Leave time set:", this.state.leaveTime);
        this.stopWatchTimer();
        break;
      case 0: // ended
        this.setState({ leaveTime: new Date().toISOString() }); // Set leave time
        console.log("Video ended. Leave time set:", this.state.leaveTime);
        this.stopWatchTimer();
        this.sendWatchTimeToServer(); // send on video end
        break;
      default:
        break;
    }

    if (typeof this.props.onStateChange === "function") {
      this.props.onStateChange(e);
    }
  };

  onPlayerReady = (e) => {
    console.log("Player is ready.");
    this.setState({ playerState: e.target });
    if (typeof this.props.onPlayerReady === "function") {
      this.props.onPlayerReady(e);
    }
  };

  onPlaybackQualityChange = (e) => {
    e.target.setPlaybackQuality("large");
  };

  render() {
    return (
      <div id="yt" className="player-bg">
        <div className="player">
          <div
            id="overlay"
            style={{
              width: "100%",
              height: "100%",
              background: "transparent",
              position: "absolute",
            }}
          />
          <div
            className="yt_player"
            id="youtube"
            ref={(r) => {
              this.youtubePlayerAnchor = r;
            }}
          />
          <YoutubeControllers
            play={this.state.playerState}
            playerStateChange={this.state.playerStateChange}
            id={this.props.YTid}
            watchTime={this.state.watchTime}
          />
        </div>
      </div>
    );
  }
}

export default YoutubePLayer;
