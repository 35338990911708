import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import store from "store";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Material-UI components
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/core/styles";

// Theme
import theme from "../../assets/v3/theme";

// APIs
import {
  getRegistrationFormConfig,
  updateStudentDetails,
} from "../../apis/UserService";

// Hooks
import useFetch from "../../hooks/useFetch";
import { useSnackbar } from "../../context/snackbar";

// Fields and utils
import { AddressFieldDetails } from "./utils";
import { useStyles, PageTitle } from ".";
import { renderFormField } from "./RenderFormField";
import { hostnamesMap } from "../../components/LogoURLMap";
import TextScroll from "./TextScroll";

const AddressDetailsForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const instituteId = hostnamesMap[window.location.hostname];
  const sid = store.get("user")._id;
  const page = "address";
  const [l1, e1, d1, f1, r1] = useFetch(getRegistrationFormConfig);
  const [l2, e2, d2, f2, r2] = useFetch(updateStudentDetails);
  const payload = d1 ? d1.payload : null;
  const fields = payload && payload.fields ? payload.fields : [];
  const details = d1 ? d1.details : {};
  const next = payload ? payload.next : null;
  const previous = payload ? payload.previous : null;
  const { openSnackbar } = useSnackbar();
  const scrollText =
    payload && payload.scroll_text ? payload.scroll_text : null;

  const formatRouteName = (route) => {
    if (!route) return "";
    return route
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fieldMap = useMemo(() => {
    return AddressFieldDetails.reduce((map, field) => {
      map[field.field] = field;
      return map;
    }, {});
  }, []);

  const initialValues = useMemo(() => {
    const initVals =  fields.reduce((values, item) => {
      const myKey =
        fieldMap && fieldMap[item.field] ? fieldMap[item.field].key : "";
      if (myKey && details && details.address) {
        values[item.field] = myKey.includes(".")
          ? details[myKey.split(".")[0]][myKey.split(".")[1]]
          : details[myKey];

        if (myKey === "dateOfBirth" && values[item.field]) {
          values[item.field] = moment(values[item.field]).format("YYYY-MM-DD");
        }
      } else {
        values[item.field] = "";
      }
      return values;
    }, {});
    if(details && details.referenceDesc) {
      initVals["referenceDesc"] = details.referenceDesc;
    }
    return initVals;
  }, [fields, details]);


  useEffect(() => {
    window.scrollTo(0, 0);
    f1({
      instituteId,
      page,
      sid,
    });

    return () => {
      r1();
    };
  }, []);

  useEffect(() => {
    if (d1) {
      console.log("Registration Form Config:", d1);
    }
    if (e1) {
      console.error("Failed to fetch registration form config:", e1);
      openSnackbar("Failed to fetch registration form config", "error");
    }
  }, [d1, e1]);

  useEffect(() => {
    if (d2) {
      console.log("Form Submitted", d2);
      if (d2.data.status === "SUCCESS") {
        openSnackbar("Address details saved successfully", "success");
      } else {
        openSnackbar("Failed to save address details", "error");
      }
      if (next) {
        history.push(`/${next}`);
      }
    }
    if (e2) {
      console.error("Failed to submit form", e2);
    }

    return () => {
      r2();
    };
  }, [d2, e2]);

  const validationSchema = Yup.object().shape(
    fields.reduce((schema, item) => {
      const field = fieldMap[item.field];
      let validator = Yup.string();
      if (field.type === "email") {
        validator = validator.email("Invalid email format");
      }

      if (field.type === "number" && item.field === "pincode") {
        validator = validator.matches(
          /^[0-9]{6}$/,
          "Must be a valid number with 6 digits"
        );
      }

      if (
        item.field === "village" ||
        item.field === "city" ||
        item.field === "district" ||
        item.field === "state"
      ) {
        validator = validator.matches(/^[a-zA-Z ]+$/, "Must be a valid name");
      }

      if (
        field.type === "text" &&
        item.field.toLowerCase().includes("number")
      ) {
        validator = validator.matches(
          /^[0-9]+$/,
          "Must be a valid phone number"
        );
      }
      if (field.type === "select") {
        validator = validator.oneOf(
          field.options,
          `Must be one of: ${field.options.join(", ")}`
        );
      }
      if (field.con === "R") {
        validator = validator.required("Required");
      }
      schema[field.field] = validator;
      return schema;
    }, {})
  );

  return (
    <ThemeProvider theme={theme}>
      {scrollText && (
        <TextScroll
          text={scrollText}
          textStyles={{
            fontWeight: "bolder",
            fontSize: "1.5rem",
            background: `-webkit-linear-gradient(#ED1B24, #074EA0)`,
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
        />
      )}
      <Box p={1} bgcolor={"background.default"} minHeight="100vh" width="100%">
        <Container
          maxWidth="md"
          bgcolor={"background.default"}
          className={classes.formContainer}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  const data = Object.keys(values).reduce((obj, f) => {
                    const myKey =
                      fieldMap && fieldMap[f] ? fieldMap[f].key : "";
                    if (myKey) {
                      obj[myKey] = values[f];
                    } else {
                      obj[f] = values[f];
                    }
                    return obj;
                  }, {});
                  f2({ sid, data, page });
                }}
                enableReinitialize={true}
              >
                {({ values, setFieldValue, touched, errors }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="h6">
                          <b>Address Details</b>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          width={"100%"}
                          height="100%"
                          alignItems="center"
                        >
                          <Typography variant="caption">
                            next: {formatRouteName(next)}
                          </Typography>
                        </Box>
                      </Grid>
                      {fields.map((field) => {
                        if (!field) return null;
                        if (!field.field) return null;
                        if (!fieldMap[field.field]) return null;
                        const fieldDescription = fieldMap[field.field];
                        return (
                          <Grid item xs={12} sm={6} md={4} key={field.field}>
                            {renderFormField({
                              field: fieldDescription,
                              handleChange: setFieldValue,
                              value: values[field.field],
                              error:
                                touched[field.field] && errors[field.field],
                              helperText: errors[field.field],
                              optionClassName: classes.option,
                              labelClassName: classes.label,
                              required: field ? field.con === "R" : false,
                              readOnly: l1,
                            })}
                          </Grid>
                        );
                      })}
                      {values && values.howDoYouKnowAboutUs && values.howDoYouKnowAboutUs === "Reference" && (
                         <Grid item xs={12} sm={6} md={4} key="abc">
                         {renderFormField({
                           field: {
                            field: "referenceDesc",
                            type: "text",
                            label: "Reference By",
                           },
                           handleChange: setFieldValue,
                           value: values.referenceDesc,
                           error:
                             touched["referenceDesc"] && errors["referenceDesc"],
                           helperText: errors["referenceDesc"],
                           optionClassName: classes.option,
                           labelClassName: classes.label,
                           required: true,
                           readOnly: false,
                         })}
                       </Grid>
                      )}
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-end"
                          flexGrow={1}
                          width="100%"
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              if (previous) {
                                history.push(`/${previous}`);
                              }
                            }}
                            disabled={l1 || l2}
                            className={classes.blueGradientBtn2}
                          >
                            Previous
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={l1 || l2}
                            className={classes.blueGradientBtn}
                          >
                            {l2 ? "Saving..." : "Next"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AddressDetailsForm;
