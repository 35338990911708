import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { ReactComponent as EventExclamation } from "../../assets/img/calendar-exclamation.svg";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import { useLocation } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Lottie from "react-lottie";
import successAnimation from "../../views/FeeDetails/success.json";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "50vh"
  }
}));
const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: successAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
function NoExams() {
  const classes = useStyles();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message = searchParams.get("message");
  const [open, setOpen] = useState(!!message);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return (
    <div style={{ margin: "auto" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <Typography align="center">
          {isOnline ? (
            <>
              <EventExclamation />
              <Typography variant="subtitle1" color="textPrimary">
                Exams not available
              </Typography>
              <Typography color="textSecondary">Come back later</Typography>
            </>
          ) : (
            <WifiOffIcon color="error" />
          )}
          {isOnline ? (
            <>
              {message ? (
                <Modal open={open} onClose={handleClose}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "white",
                      padding: "20px",
                      boxShadow: 24,
                      outline: "none",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      borderRadius: "10px"
                    }}
                  >
                    <Lottie options={defaultOptions} height={100} width={100} />
                    {message.includes("Congratulations") ? (
                      <Typography
                        variant="h5"
                        style={{ color: "green" }}
                        align="center"
                      >
                        Congratulations!
                      </Typography>
                    ) : null}
                    &nbsp;
                    <Box
                      style={{
                        // backgroundColor: "lightgrey",
                        margin: "2px",
                        padding: "2px",
                        borderRadius: "5px"
                      }}
                    >
                      <Typography variant="body" color="primary" align="center" 
                      style={{
                        lineHeight: "2.5px"
                      }}>
                        {message
                          .replace("Congratulations!", "")
                          .replace(",title=", "")}
                      </Typography>
                    </Box>
                    &nbsp;
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="small"
                      color="secondary"
                    >
                      Close
                    </Button>
                  </Box>
                </Modal>
              ) : null}
            </>
          ) : (
            <>
              <Typography variant="subtitle1" color="error">
                You are Offline
              </Typography>
              <Typography color="textSecondary">
                Check your internet connection and try again.
              </Typography>
            </>
          )}
        </Typography>
      </Box>
    </div>
  );
}

export default NoExams;
