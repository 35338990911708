// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import sectionsPageStyle from "assets/jss/material-kit-pro-react/views/sectionsPageStyle.jsx";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import React from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
// import Footer from "./Footer";
import SectionContacts from "./Sections/SectionContacts1.jsx";
// sections of this Page
import SectionFeatures from "./Sections/SectionFeatures.jsx";
import SectionLanding from "./Sections/SectionLanding";
import SectionPricing from "./Sections/SectionPricing.jsx";
import store from "store";
import LandingPage from "../Landing";


class SectionsPage extends React.Component {
  componentDidMount() {
    // var href = window.location.href.substring(
    //   window.location.href.lastIndexOf("#") + 1
    // );
    // if (window.location.href.lastIndexOf("#") > 0)
    //   document.getElementById(href).scrollIntoView();
    // window.addEventListener("scroll", this.updateView);
    // this.updateView();
  }
  componentDidUpdate() {
    //   var href = window.location.href.substring(
    //     window.location.href.lastIndexOf("#") + 1
    //   );
    //  if(document.getElementById(href)!==null) document.getElementById(href).scrollIntoView();
  }
  componentWillUnmount() {
    // window.removeEventListener("scroll", this.updateView);
  }
  easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }
  updateView() {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");

    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
          window.pageYOffset &&
        contentSections[i].offsetTop +
          contentSections[i].scrollHeight -
          window.innerHeight / 2 >
          window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  }
  smoothScroll(target) {
    var targetScroll = document.getElementById(target);
    if (targetScroll)
      this.scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  }
  scrollGo(element, to, duration) {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }.bind(this);
    animateScroll();
  }

  render() {
    const { classes } = this.props;
    const instituteId = store.get("user") && store.get("user").institute;
    console.log(window.location.hostname);
    console.log("instituteId", instituteId);
    return (
      <div>
        <Header
          color="info"
          brand="Onesaz"
          links={<HeaderLinks dropdownHoverColor="info" />}
          // fixed
        />
        {/* {window.location.hostname === "tirumala.onesaz.com" ||
        window.location.hostname.includes("192.168.") ? (
          <LandingPage />

        ) : ( */}
          <div className={classes.main}>
            {/* <SectionHeaders id="about" /> */}
            <SectionLanding id="about" />
            <SectionFeatures id="features" />
            {/* <SectionBlogs id="blogs" /> */}

            {/* <SectionProjects id="projects" /> */}
            {/* <SectionPricing id="pricing" /> */}
            {/* <SectionTeams id="teams" /> */}
            {/* <SectionTestimonials id="testimonials" /> */}
            <SectionContacts id="contact" />

            {/* <Footer /> */}
          </div>
        {/* )} */}

        {/* <nav id="cd-vertical-nav">
          <ul>
            <li>
              <a
                href="#about"
                data-number="1"
                className="is-selected"
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("about");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">About</span>
              </a>
            </li>
            <li>
              <a
                href="#features"
                data-number="2"
                className=""
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("features");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">Features</span>
              </a>
            </li>
           
            <li>
              <a
                href="#pricing"
                data-number="3"
                className=""
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("pricing");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">Pricing</span>
              </a>
            </li>
              
            <li>
              <a
                href="#contact"
                data-number="4"
                className=""
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("contact");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">Contact Us</span>
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(sectionsPageStyle)
)(SectionsPage);
