import React, { useState } from "react";
import store from "store";
import moment from "moment";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RegularButton from "../../../components/CustomButtons/Button";
import WrapperCard from "../Components/WrapperCard";
import TypographyAutocomplete from "./Components/TypographyAutocomplete";
import useFetch from "../../../hooks/useFetch";
import { postParentConcern } from "../../../apis/parentConcerns";
import Header from "./Components/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 600,
    margin: "auto",
  },
  wrapperCard: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  bigtitle: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  submitButton: {
    float: "right",
    marginTop: theme.spacing(2),
  },
}));

const INITIAL_TRANSPORT_STATE = {
  changeroute: {
    pickupType: "two-way",
    pickupPlace: "",
    dropPlace: "",
  },
  requesttransport: {
    address: "",
    landmark: "",
    pickupType: "two-way",
    pickupPlace: "",
    dropPlace: "",
    availDate: "",
  },
};

const CONCERN_TYPES = {
  GENERAL: "GENERAL_PARENT_CONCERN",
  // ACADEMIC: "ACADEMIC_PARENT_CONCERN",
  // FEE: "FEE_PARENT_CONCERN",
  // TRANSPORT: "TRANSPORT_PARENT_CONCERN",
};

const CATEGORY_TYPES = {
  CHANGE_ROUTE: "Change_Route/Stage",
  REQUEST_TRANSPORT: "Request_for_Transport",
};

export default function GeneralConcern() {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    concernType: "",
    category: "",
    details: "",
    transport: INITIAL_TRANSPORT_STATE,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [formError, setFormError] = useState("");

  const userData = store.get("user");
  const institute = userData ? userData.institute : "";
  const branchId = userData ? userData.branchId : "";
  const studentIds = userData ? userData.studentIds : "";
  const userId = userData ? userData.userId : "";

  const [isLoading, error, data, postData] = useFetch(postParentConcern);

  const validateForm = () => {
    if (!formState.concernType) {
      setFormError("Please select a concern type");
      return false;
    }

    if (formState.concernType === CONCERN_TYPES.TRANSPORT) {
      if (!formState.category) {
        setFormError("Please select a transport category");
        return false;
      }

      const categoryKey =
        formState.category === CATEGORY_TYPES.CHANGE_ROUTE
          ? "changeroute"
          : "requesttransport";
      const transportDetails = formState.transport[categoryKey];

      if (!transportDetails.pickupPlace && !transportDetails.dropPlace) {
        setFormError("Pickup or Drop place is required");
        return false;
      }

      if (
        formState.category === CATEGORY_TYPES.REQUEST_TRANSPORT &&
        (!transportDetails.address || !transportDetails.landmark)
      ) {
        setFormError(
          "Address and Landmark are required for transport requests"
        );
        return false;
      }
    }

    if (!formState.details.trim()) {
      setFormError("Please provide details of your concern");
      return false;
    }

    setFormError("");
    return true;
  };

  const getCategoryKey = () => {
    return formState.category === CATEGORY_TYPES.CHANGE_ROUTE
      ? "changeroute"
      : "requesttransport";
  };

  const isRequestTransport = () => {
    return formState.category === CATEGORY_TYPES.REQUEST_TRANSPORT;
  };

  const handleChangeDate = (date) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = moment(date).format("DD/MM/YYYY");
      updateTransportField("availDate", formattedDate);
    }
  };

  const updateTransportField = (field, value) => {
    const categoryKey = getCategoryKey();
    setFormState((prev) => ({
      ...prev,
      transport: {
        ...prev.transport,
        [categoryKey]: {
          ...prev.transport[categoryKey],
          [field]: value,
        },
      },
    }));
  };

  const handleChange = (e, newValue, field) => {
    if (field === "concernType") {
      setFormState((prev) => ({
        ...prev,
        concernType: newValue || "",
        category: "", // Reset category when concern type changes
      }));
    } else if (field === "category") {
      setFormState((prev) => ({
        ...prev,
        category: newValue || "",
      }));
    } else if (field === "details") {
      const value =
        e && e.target && e.target.value !== undefined ? e.target.value : "";
      setFormState((prev) => ({
        ...prev,
        details: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      const categoryKey = getCategoryKey();
      const transportDetails = formState.transport[categoryKey];
      const formattedTransportDetails =
        formState.concernType === CONCERN_TYPES.TRANSPORT
          ? {
              ...transportDetails,
              category: formState.category,
            }
          : undefined;
      const formData = {
        description: formState.details,
        branch: branchId,
        instituteId: institute,
        requestType: formState.concernType,
        studentId: studentIds,
        status: "Open",
        academicYear: "2024-2025",
        parentId: userId,
        transportDetails: formattedTransportDetails,
      };

      await postData(formData);

      setFormState({
        concernType: "",
        category: "",
        details: "",
        transport: INITIAL_TRANSPORT_STATE,
      });
      setSelectedDate(null);
      setFormError("");
    } catch (err) {
      setFormError("Failed to submit concern. Please try again.");
      console.error("Submit error:", err);
    }
  };

  const renderTransportForm = () => {
    if (!formState.category) return null;

    const categoryKey = getCategoryKey();
    const transport = formState.transport[categoryKey];

    if (!transport) return null;

    const showPickupField =
      transport.pickupType === "two-way" ||
      transport.pickupType === "one-way-pickup";
    const showDropField =
      transport.pickupType === "two-way" ||
      transport.pickupType === "one-way-drop";

    return (
      <WrapperCard className={classes.wrapperCard}>
        <Typography variant="h6" className={classes.bigtitle}>
          {isRequestTransport()
            ? "Transport Request Details"
            : "Route Change Details"}
        </Typography>

        <Box mb={3}>
          <Typography className={classes.title}>Pickup Type</Typography>
          <RadioGroup
            value={transport.pickupType}
            onChange={(e) => updateTransportField("pickupType", e.target.value)}
            row
          >
            <FormControlLabel
              value="two-way"
              control={<Radio />}
              label="Two Way"
            />
            <FormControlLabel
              value="one-way-pickup"
              control={<Radio />}
              label="One Way Pickup"
            />
            <FormControlLabel
              value="one-way-drop"
              control={<Radio />}
              label="One Way Drop"
            />
          </RadioGroup>
        </Box>

        {isRequestTransport() && (
          <>
            <TypographyAutocomplete
              typographyText="Address *"
              useTextFieldOnly
              textFieldProps={{
                value: transport.address || "",
                onChange: (e) =>
                  updateTransportField("address", e.target.value),
                variant: "outlined",
                fullWidth: true,
                required: true,
              }}
            />
            <TypographyAutocomplete
              typographyText="Landmark *"
              useTextFieldOnly
              textFieldProps={{
                value: transport.landmark || "",
                onChange: (e) =>
                  updateTransportField("landmark", e.target.value),
                variant: "outlined",
                fullWidth: true,
                required: true,
              }}
            />
          </>
        )}

        {showPickupField && (
          <TypographyAutocomplete
            typographyText="Pickup Place *"
            useTextFieldOnly
            textFieldProps={{
              value: transport.pickupPlace || "",
              onChange: (e) =>
                updateTransportField("pickupPlace", e.target.value),
              variant: "outlined",
              fullWidth: true,
              required: true,
            }}
          />
        )}

        {showDropField && (
          <TypographyAutocomplete
            typographyText="Drop Place *"
            useTextFieldOnly
            textFieldProps={{
              value: transport.dropPlace || "",
              onChange: (e) =>
                updateTransportField("dropPlace", e.target.value),
              variant: "outlined",
              fullWidth: true,
              required: true,
            }}
          />
        )}

        {isRequestTransport() && (
          <Box mt={2}>
            <Typography variant="h6">Date to Avail transport*</Typography>
            <DatePicker
              selected={selectedDate}
              onChange={handleChangeDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Date"
              className="w-full p-2 border rounded"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isClearable
              minDate={new Date()}
            />
            {selectedDate && (
              <Typography variant="body2" className="mt-2 text-gray-600">
                Selected date: {moment(selectedDate).format("DD/MM/YYYY")}
              </Typography>
            )}
          </Box>
        )}
      </WrapperCard>
    );
  };

  return (
    <Box className={classes.container}>
      <Header title="General Concerns" path="/parentconcern" />
      <form onSubmit={handleSubmit}>
        <TypographyAutocomplete
          typographyText="Concern Type"
          options={Object.values(CONCERN_TYPES)}
          autocompleteProps={{
            value: formState.concernType,
            onChange: (e, newValue) => handleChange(e, newValue, "concernType"),
            placeholder: "-Select-",
          }}
        />

        {formState.concernType === CONCERN_TYPES.TRANSPORT && (
          <TypographyAutocomplete
            typographyText="Category"
            options={Object.values(CATEGORY_TYPES)}
            autocompleteProps={{
              value: formState.category,
              onChange: (e, newValue) => handleChange(e, newValue, "category"),
              placeholder: "-Select Category-",
            }}
          />
        )}

        {formState.concernType === CONCERN_TYPES.TRANSPORT &&
          renderTransportForm()}

        <TypographyAutocomplete
          className={classes.textField}
          typographyText="Details of the Concern"
          useTextFieldOnly
          textFieldProps={{
            value: formState.details,
            onChange: (e) => handleChange(e, undefined, "details"),
            multiline: true,
            rows: 4,
            placeholder: "Enter details of your concern here",
            className: classes.textField,
            required: true,
          }}
        />

        {formError && (
          <Typography variant="body2" className={classes.errorText}>
            {formError}
          </Typography>
        )}

        <Box className={classes.submitButton}>
          <RegularButton
            variant="contained"
            type="submit"
            color="facebook"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </RegularButton>
        </Box>
      </form>
    </Box>
  );
}