import React from "react";
import PropTypes from "prop-types";
import { Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  typography: {
    fontSize: "1rem",
    marginBoottom: theme.spacing(1),
    fontWeight: 700,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.dark,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.dark,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.dark,
      },
    },
  },
  textField: {
    width: "100%", // Default width is 100%
    height: "auto", // Default height is auto
    margin: "0rem",

    "& .MuiInputBase-root": {
      minHeight: "40px",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
    },
  },
}));

const TypographyAutocomplete = ({
  // Typography Props
  typographyProps,
  typographyText,

  // Autocomplete Props
  autocompleteProps,
  options,

  // TextField Props
  textFieldProps,
  useTextFieldOnly, // Toggle TextField only mode
  minHeight,

  // Styling
  containerStyle,
  containerClassName,
}) => {
  const classes = useStyles();

  return (
    <div
      style={containerStyle}
      className={`${classes.container} ${containerClassName}`}
    >
      <Typography className={classes.typography} {...typographyProps}>
        {typographyText}
      </Typography>

      {useTextFieldOnly ? (
        <TextField
          variant="outlined"
          className={classes.textField}
          style={{ minHeight }}
          required
          {...textFieldProps}
        />
      ) : (
        <Autocomplete
          options={options}
          className={classes.autocomplete}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              className={classes.textField}
              style={{ minHeight }}
              required
              {...textFieldProps}
            />
          )}
          {...autocompleteProps}
        />
      )}
    </div>
  );
};

// Default Props
TypographyAutocomplete.defaultProps = {
  typographyProps: {},
  typographyText: "Select an Option",
  options: [],
  autocompleteProps: {
    size: "small",
    disableClearable: true,
  },
  textFieldProps: {},
  useTextFieldOnly: false,
  minHeight: "40px", // Default minimum height
  containerStyle: {},
  containerClassName: "",
};

TypographyAutocomplete.propTypes = {
  typographyProps: PropTypes.object,
  typographyText: PropTypes.node,
  options: PropTypes.array,
  autocompleteProps: PropTypes.object,
  textFieldProps: PropTypes.object,
  useTextFieldOnly: PropTypes.bool, // Toggle to use TextField only
  minHeight: PropTypes.string, // Minimum height for TextField
  containerStyle: PropTypes.object,
  containerClassName: PropTypes.string,
};

export default TypographyAutocomplete;
