import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import * as dateFns from "date-fns";
import { useMediaQuery } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import store from "store";
import DonutChart from "./DonutChart";
import { getStudentAttendance,getZktAttendance,getDeviceInfo } from "../../apis/attendance";
import useStudentData from "../Dashboard/Components/useStudentData";
import institutes from "../../common/institutes";
import axios from "axios";
import PunchesData from "./PunchesData";
import TodayClassAttendance from "./TodayClassAttendance";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import licensed_users from "./ZoomAttendance/zoomUsers";
import Calendar from "./Calendar";
import {
  getLast12Months,
  getDaysInMonth,
  getEmptySlots,
  getFirstDayOfMonth
} from "./calendarUtils";
import ResultHeader from "../resultPage/ResultHeader";
import WrapperCard from "../HomePage/Components/WrapperCard";

const AttCalendar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [type, setType] = useState("");
  const [studentAdmissionNo, , stuFeeData] = useStudentData();
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [monthFromDate, setMonthFromDate] = useState(null);
  const [monthToDate, setMonthToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [todayRes, setTodayRes] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [res, setRes] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false); // Add loading state
  const months = getLast12Months();
  const [bioRes, setBioRes] = useState(null);
  const [fetchedZoomData, setFetchedZoomData] = useState([]);
  const today = new Date();
  const [studentData, setStudentData] = useState([]);
  const [MonthPunchesdata, setMonthPunchesData] = useState([]);
  const [selectAttType, setAttType] = useState("Class Attendance");
  const latestMonthIndex = months.findIndex((month) =>
    dateFns.isSameMonth(today, new Date(month.year, month.month, 1))
  );
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (selectedMonthIndex === null) {
      setSelectedMonthIndex(latestMonthIndex);
    }
  }, [selectedMonthIndex, latestMonthIndex]);

  const selectedMonth = months[selectedMonthIndex];
  const allMonthDates = selectedMonth
    ? getDaysInMonth(selectedMonth.year, selectedMonth.month)
    : [];
  const firstDayOfMonth = selectedMonth
    ? getFirstDayOfMonth(selectedMonth.year, selectedMonth.month)
    : 0;
  const emptySlots = getEmptySlots(firstDayOfMonth);
  useEffect(() => {
    if (stuFeeData) {
      if (stuFeeData.config) {
        if (stuFeeData.config[0].attendanceConf) {
          setType(stuFeeData.config[0].attendanceConf.type);
        } else setType("");
      }
    }
  }, [stuFeeData]);
  useEffect(() => {
    if (selectedMonthIndex !== null) {
      const startDate =
        allMonthDates && allMonthDates.length > 0 ? allMonthDates[0] : null;
      const endDate = selectedMonth
        ? new Date(selectedMonth.year, selectedMonth.month + 1, 0)
        : null;

      setMonthFromDate(startDate);
      setMonthToDate(endDate);
    }
  }, [selectedMonthIndex]);

  useEffect(() => {
    if (selectedDate !== undefined && selectedDate) {
      setFromDate(selectedDate);
      setToDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    let isMounted = true;
    if (monthFromDate && monthToDate && (count === 0 || count > 0)) {
      setLoading(true); // Set loading to true before fetching data
      if (type === "auto") {
        setMessage(null);
        fetchBioAtt(monthFromDate, monthToDate, isMounted);
      } else if (selectAttType === "Class Attendance") {
        setMessage(null);
        fetch(monthFromDate, monthToDate, isMounted,"month",studentAdmissionNo);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [monthFromDate, monthToDate, type,studentAdmissionNo]);

  useEffect(() => {
    let isMounted = true;
    if (fromDate && toDate) {
      setLoading(true); // Set loading to true before fetching data
      if (type === "auto") {
        setMessage(null);
        fetchBioAtt(fromDate, toDate, isMounted, "today");
      } else if (selectAttType === "Class Attendance") {
        setMessage(null);
        fetch(fromDate, toDate, isMounted, "today",studentAdmissionNo);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [fromDate, toDate, type,studentAdmissionNo]);

  const handleMonthChange = (event) => {
    setSelectedMonthIndex(event.target.value);
    setSelectedDate(null);
    setCount(count + 1);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setCount(count + 1);
  };

  const instituteId = store.get("user").institute;
  const host = `https://${institutes[instituteId]}/api`;

  const fetchBioAtt = async (fDate, tDate, isMounted, mode) => {
    setMessage(null);
    try {
      const admissionType = (stuFeeData.student.admissionType).toUpperCase();
      const deviceInfo = await getDeviceInfo({ admissionType });
      if (deviceInfo && deviceInfo.result) {
        const academicDevices = deviceInfo.result
          .filter((device) => device.locationType === "ACADEMIC")
          .map((device) => device.deviceId);
        const residentialDevices = deviceInfo.result
          .filter((device) => device.locationType === "RESIDENTIAL")
          .map((device) => device.deviceId);

        const fetchAttendance = async (terminals) => {
          return await getZktAttendance({
            start_date: dateFns.format(fDate, "yyyy-MM-dd"),
            end_date: dateFns.format(tDate, "yyyy-MM-dd"),
            admissionNo: stuFeeData.student.admissionNo,
            instituteId: store.get("user").institute,
            terminals,
          });
        };

        let res = {  result: { attendance: [] }  };

        if (admissionType === "RESIDENTIAL") {
          const academicRes = await fetchAttendance(academicDevices);
          const residentialRes = await fetchAttendance(residentialDevices);
            res.result.attendance = [
            ...academicRes.result.attendance,
            ...residentialRes.result.attendance.map(record => ({
              ...record,
              type: "Residential"
            })),
            ];
        } else {
          res = await fetchAttendance(academicDevices);
        }

        if (isMounted) {
          if (res&&res.result) {
            if (res.result.attendance) {
              if (mode === "today") {
                setBioRes(res.result.attendance);
              } else {
                setMonthPunchesData(res.result.attendance);
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error fetching data");
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  const fetch = async (fDate, tDate, isMounted, mode,studentAdmissionNo) => {
    try {
      if(studentAdmissionNo){
      const res = await getStudentAttendance({
        fromDate: dateFns.format(fDate, "yyyy-MM-dd"),
        toDate: dateFns.format(tDate, "yyyy-MM-dd"),
        admissionNo:studentAdmissionNo,
        instituteId: store.get("user").institute
      });
      if (isMounted) {
        if (res.result.attendanceReport) {
          if (mode === "today") {
            setTodayRes(res.result.attendanceReport);
      
            setMessage(res.message);
          } else {
            setRes(res.result.attendanceReport);
          }
        }
      }
    }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error fetching data");
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };


  // zoom Attendance
  async function fetchAllZoomData() {
    const level = store.get("user").level;

    try {
      const response = await axios.get(
        `${host}/live/getAllVideos?instituteId=${instituteId}_${level}&provider=zoom`
      );
      setFetchedZoomData(response.data);
    } catch (error) {
      console.error(error);
      // setMessage("Error fetching data");
    }
  }

  useEffect(() => {
    if (selectAttType === "Online Attendance") {
      fetchAllZoomData();
    }
  }, [selectAttType]);
  // Zoom Attendance
  useEffect(() => {
    let meetingIds;
    if (fetchedZoomData.Items) {
      meetingIds = fetchedZoomData.Items.map((item) => item.meetingId); // Replace with your array of meetingIds
    }

    async function fetchAttendanceData(fDate, meetingIds) {
      try {
        const queryParams = {
          branches: [store.get("user").branchId],
          dates: { from: dateFns.format(fDate, "yyyy-MM-dd") },
          provider: "zoom",
          instituteId: store.get("user").institute,
          level: store.get("user").level,
          zoomId: licensed_users[store.get("user").level]
            ? licensed_users[store.get("user").level].id
            : "",
          admissionNo: store.get("user").userId
        };

        const requests = meetingIds.map((meetingId) =>
          axios.get(`${host}/zoom/attendanceZoomMeeting/${meetingId}`, {
            params: queryParams
          })
        );
        const responses = await axios.all(requests);
        const allData = [];
        const noData = [];
        // Process responses as needed
        for (let i = 0; i < meetingIds.length; i++) {
          const meetingId = meetingIds[i];
          const response = responses[i];
          if (response.status === 200) {
            const data = response.data;

            if (data.attendanceData.length === 0) {
              noData.push(meetingId);
            } else {
              allData.push(data);
            }
          } else {
            console.error(`Failed to fetch data for meetingId ${meetingId}`);
          }
        }
        setStudentData(allData);
        // setAbsentMeetings(noData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessage("Error fetching data");
      }
    }

    if (
      meetingIds &&
      meetingIds.length > 0 &&
      fromDate &&
      toDate &&
      selectedDate &&
      selectAttType === "Online Attendance"
    ) {
      // Call the function to fetch data for all meetings
      fetchAttendanceData(fromDate, meetingIds);
    }
  }, [fetchedZoomData, fromDate, toDate, selectedDate]);

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const items = ["Class Attendance", "Online Attendance"];

  const presentDates =
    res &&
    Object.entries(res).reduce((acc, [key, value]) => {
      if (value === "P") {
        const [day, month, year] = key.split("_").map(Number);
        // Note: month is 0-indexed in JavaScript Date, so subtract 1
        acc.push(new Date(year + 2000, month - 1, day));
      }
      return acc;
    }, []);

  const presentPunchesDates =
    MonthPunchesdata &&
    MonthPunchesdata.reduce((acc, record) => {
      if (record.punches.length > 0) {
        const [year, month, day] = record.date.split("-").map(Number);
        // Note: month is 0-indexed in JavaScript Date, so subtract 1
        acc.push(new Date(year, month - 1, day));
      }
      return acc;
    }, []);
  return (
    <>
      {/* <Typography
        variant="h6"
        style={{ color: "#FF0000", textAlign: "center", paddingTop: "10px" }}
      >
        {message === "Error fetching data" ? "Attendance Not Availble" : ""}
      </Typography> */}

      {loading ? ( // Display loading message or spinner
        <Typography variant="h6" style={{ textAlign: "center", paddingTop: "10px" }}>
          Loading...
        </Typography>
      ) : (res || bioRes||todayRes) && Object.keys(res || bioRes||todayRes).length > 0 && (
        <>
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <ResultHeader
                  testDisplayName="View Attendance"
                  path="/home-page"
                />
              </Grid>

              <Grid item xs={4}>
                <div className={classes.title}>
                  <Link to="/leave-application">
                    <Grid container direction="row" alignItems="center">
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#3358B8",
                          cursor: "pointer"
                        }}
                      >
                        Apply Leave
                      </div>
                    </Grid>
                  </Link>
                </div>
              </Grid>
              <Grid item container xs={12} conatiner disply="flex" spacing={1}>
                {items.map((item) => {
                  return (
                    <Grid item>
                      <Button
                        key={`${item}`}
                        variant="contained"
                        size="small"
                        color={item === selectAttType ? "primary" : ""}
                        className={
                          item === selectAttType
                            ? classes.activeBtn
                            : classes.inActiveBtn
                        }
                        onClick={() => setAttType(item)}
                      >
                        {item}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </div>
          {selectAttType === "Class Attendance" && (
            <Grid
              container
              spacing={2}
              style={{ paddingLeft: "25px", paddingRight: "25px" }}
            >
              <Calendar
                classes={classes}
                theme={theme}
                isMobile={isMobile}
                selectedMonthIndex={selectedMonthIndex}
                handleMonthChange={handleMonthChange}
                months={months}
                weekdays={weekdays}
                emptySlots={emptySlots}
                allMonthDates={allMonthDates}
                selectedDate={selectedDate}
                handleDateClick={handleDateClick}
                holidays={
                  type !== "auto"
                    ? presentDates || []
                    : presentPunchesDates || []
                }
              />
              {type !== "auto" ? (
                <>
                  <Grid item xs={isMobile ? 12 : 6}>
                    <div className={classes.attendanceDetails}>
                      <div className={classes.root}>
                        <TodayClassAttendance todayRes={todayRes} />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    <div className={classes.attendanceDetails}>
                      <div className={classes.root}>
                        <DonutChart
                          data={res&&res||{}}
                          todayRes={todayRes&&todayRes||{}}
                          monthlabel={months[selectedMonthIndex].label}
                          allMonthDatesLength={allMonthDates.length}
                        />
                      </div>
                    </div>
                  </Grid>
                  <br />
                  <br /> <br />
                  <br />
                </>
              ) : (
                <Grid item xs={isMobile ? 12 : 6}>
                  <div className={classes.attendanceDetails}>
                    <div className={classes.root}>
                      {bioRes && <PunchesData data={bioRes} />}
                    </div>
                  </div>
                </Grid>
              )}
              &nbsp;
              <br />
            </Grid>
          )}
          {selectAttType === "Online Attendance" && (
            <>
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: "25px", paddingRight: "25px" }}
              >
                <Calendar
                  classes={classes}
                  theme={theme}
                  isMobile={isMobile}
                  selectedMonthIndex={selectedMonthIndex}
                  handleMonthChange={handleMonthChange}
                  months={months}
                  weekdays={weekdays}
                  emptySlots={emptySlots}
                  allMonthDates={allMonthDates}
                  selectedDate={selectedDate}
                  handleDateClick={handleDateClick}
                  holidays={[]}
                />

                <Grid item xs={isMobile ? 12 : 6}>
                  {fetchedZoomData.Items &&
                    (studentData.length === 0 ? (
                      <WrapperCard>
                        <Typography color="primary">
                          <b>
                            {selectedDate &&
                              dateFns.format(selectedDate, "dd-MM-yyyy")}
                          </b>
                          &nbsp; :&nbsp;
                          <b style={{ color: "#FC5132", fontSize: "20px" }}>
                            Absent
                          </b>
                        </Typography>
                      </WrapperCard>
                    ) : (
                      <WrapperCard>
                        <Typography>Present</Typography>
                      </WrapperCard>
                    ))}
                </Grid>
              </Grid>
              <br /> <br /> <br /> <br />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AttCalendar;
