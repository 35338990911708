import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import { Grid, Typography } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
import onesazlogo from "assets/v2/onesaz.png";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import { logoutUser } from "../../apis/UserService";
import NotificationTab from "../../mycomponents/inAppNotification/Index";
import institutesLogo from "./headerLogos";
import { capitalize } from "lodash";
import theme from "../../assets/theme";
import AlertComponent from "../../mycomponents/AlertComponent";
import clearLocalStorage from "../../common/clearStore";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      confirmOpen: false,
      alertText: "",
      logo:
        institutesLogo[window.location.hostname] ||
        institutesLogo["onesaz.com"],
      collegeName: window.location.hostname.split(".")[0],
    };
    props = this.props;
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.headerColorChange = this.headerColorChange.bind(this);
    this.logout = this.logout.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }
  handleCloseAlertBox = () => {
    this.setState({ confirmOpen: false, alertText: "" });
  };
  async logout() {
    const exam = store.get("startedExam");
    if (exam && exam.test_status === "STARTED") {
      this.setState({
        confirmOpen: true,
        alertText: `Please, Submit Test : ${
          exam.testDisplayName
        } Before Logging Out!`,
      });
    } else {
      const { history } = this.props;
      const user = store.get("user");
      const _id = user ? user._id : "";
      const phase = user ? user.phase : "";
      await logoutUser({ id: _id });
      clearLocalStorage();
      if (phase && phase === "enquiry") {
        history.push("/reg-login");
      } else {
        history.push("/sign-in");
      }
    }
  }
  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
  }
  headerColorChange() {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }
  render() {
    const { classes, color, links, fixed, absolute } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
    });
    return (
      <AppBar className={appBarClasses}>
        <Toolbar
          className={classes.container}
          style={{ backgrounColor: "white" }}
        >
          <Hidden only={["md", "lg", "sm", "xl"]}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu style={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Hidden>
          {/* <GridContainer justify="space-between">  */}
          <Grid item xs={8} sm={10}>
            <Button className={classes.title}>
              <Link
                to={
                  ["2119710"].includes(
                    `${store.get("user") ? store.get("user").level : ""}`
                  )
                    ? "/home"
                    : "/dashboard"
                }
              >
                {/* {Branding for different institutions } */}
                <Grid container direction="row" alignItems="center">
                  {/*  aspect ratio => width: 37 and height: 10 */}
                  <img style={{ width: 45 }} src={this.state.logo} alt="..." />
                  &nbsp;
                  <Typography
                    style={{
                      fontSize: "smaller",
                      fontWeight: "bold",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {this.state.collegeName.toUpperCase()}
                  </Typography>
                </Grid>
              </Link>
            </Button>
          </Grid>
          {/* <GridItem xs={6}> 
            <Button onClick={this.logout} style={{marginLeft:"auto"}} >

               
               <Logout/>
            </Button>
            </GridItem> */}
          <Hidden smDown implementation="css" className={classes.hidden}>
            <div className={classes.collapse}>{links}</div>
          </Hidden>
          <Hidden only={["md", "lg", "xs", "xl"]}>
            {/* ----------Notification tab-------------- */}
            {store.get("user") ? <NotificationTab ifMobile={true} /> : null}
            {/* ---------------------------------------- */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu style={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Hidden>
          <Hidden only={["md", "lg", "sm", "xl"]}>
            {/* ----------Notification tab-------------- */}
            {store.get("user") ? <NotificationTab ifMobile={true} /> : null}
            {/* ---------------------------------------- */}
          </Hidden>
          {/* </GridContainer> */}
        </Toolbar>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={this.state.mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={this.handleDrawerToggle}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.closeButtonDrawer}
            >
              <Close />
            </IconButton>
            <div className={classes.appResponsive}>{links}</div>
            {/* <Button onClick={this.logout} >

               
               <Logout/>
            </Button> */}
          </Drawer>
        </Hidden>
        <AlertComponent
          open={this.state.confirmOpen}
          closeAlert={this.handleCloseAlertBox}
        >
          {this.state.alertText}
        </AlertComponent>
      </AppBar>
    );
  }
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};

export default compose(
  withRouter,
  withStyles(headerStyle)
)(Header);
