import React from 'react';
import { Typography, Box, Container, Button, withStyles, Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import svgImag from "../SectionsPage/Sections/logos/hero frame.svg";
import tirumalabg from "../../assets/tirumalabg.jpg";
import RegFooter from "../Registration/RegFooter.jsx";
import tirumalaLogo from "../../assets/tirumalaLogo.jpg";

const useStyles = makeStyles((theme) => ({
    heroSection: {
        height: '60vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url("${svgImag}")`,
       
        color: '#fff',
        padding: '10px',
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        position: 'relative',
        zIndex: 1,
        
    },
    logo: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        width: '50px',
        height: '50px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            marginBottom: theme.spacing(2),
        },
    },
    logoRight: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '50px',
        height: '50px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 'auto',
            right: 'auto',
            marginBottom: theme.spacing(2),
        },
    },
    button: {
        marginTop: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(6, 2),
        textAlign: 'center',
    },
    branchContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // margin: theme.spacing(2, 0),
        textAlign: "center",
        flexDirection: "column", // Default to column for mobile
        [theme.breakpoints.up('md')]: {
            flexDirection: "row", // Change to row for desktop
        },
    },
    branchItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: theme.spacing(2), // Add margin for mobile
        [theme.breakpoints.up('md')]: {
            marginBottom: 0, // Remove margin for desktop
        },
    },
    branchName: {
        fontWeight: "bold",
        color: "brown",
        textAlign: "center", // Center align the branch name
    },
    branchAddress: {
        fontSize: "14px",
        color: "gray",
    },
    divider: {
        height: "60px", // Adjust to match text height
        margin: "2px 0", // Margin for mobile
        backgroundColor: "brown",
        [theme.breakpoints.up('md')]: {
            margin: "0 14px", // Margin for desktop
        },
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '70vh',
    },
    contentContainer: {
        flex: 1,
    },
}));

const branches = [
    { name: "RAJAMAHENDRAVARAM", address: "Katheru, Rajahmundry – 533105" },
    { name: "BHIMAVARAM", address: "OPP: NEAR PRAKRUTHI ASHRAMAM" },
    { name: "VISAKAPATNAM", address: "N.H-16, RAJULA TALLAVALASA" },
    { name: "TANUKU", address: "Iragavaram Road, Komaravaram" },
    { name: "Payakaraopeta", address: " P.L. Puram Centre, beside N.H – 16" },

];

const TirumalaPage = () => {
    const classes = useStyles();
    return (
        <div className={classes.pageContainer}>
            <div className={classes.contentContainer}>
                <Box className={classes.heroSection}>
                    <img 
                        src={tirumalaLogo} 
                        alt="Tirumala Logo" 
                        style={{ width:"200px",
                            height:"100px",}}
                        className={classes.logo} 
                    />
                    <img 
                        src="https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/tirumala_mini.png" 
                        alt="Tirumala Logo" 
                        className={classes.logoRight} 
                        style={{ 
                            display: 'block', 
                            margin: '0 auto', 
                        width:"100px",
                        height:"100px",
                      marginBottom: '10px' 
                        }} 
                    />
                    <Box className={classes.overlay} />
                    <Box className={classes.content}>
                        <Typography variant="h3" gutterBottom>
                            Welcome to Tirumala Educational Institutes
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Experience the excellence of education in a spiritual atmosphere.
                        </Typography>
                    </Box>
                </Box>
             &nbsp;
                <Box className={classes.branchContainer}>
                    {branches.map((branch, index) => (
                        <React.Fragment key={index}>
                            <Box className={classes.branchItem}>
                                <Typography variant="h6" className={classes.branchName}>
                                    {branch.name}
                                </Typography>
                                <Typography variant="body2" className={classes.branchAddress}>
                                    {branch.address}
                                </Typography>
                            </Box>
                            {index < branches.length - 1 && <Divider orientation="vertical" flexItem className={classes.divider} />}
                        </React.Fragment>
                    ))}
                </Box>
            </div>
            <RegFooter />
        </div>
    );
};

export default TirumalaPage;
