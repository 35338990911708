import axios from "./examServerAxios";

export async function initiateOnlineTransaction(body, provider = "onepay") {
  try {
    let data = await axios.post(`/pay/initiateTxn/${provider}`, body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function initiateGateWayTxn(body) {
  const { provider, ...rest } = body;
  try {
    let data = await axios.post(`/pay/${provider}`, { ...rest, provider });
    return data;
  } catch (error) {
    return error;
  }
}
