// import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'
// import Container from '@material-ui/core/Container'
// import Grid from '@material-ui/core/Grid'

// const useStyles = makeStyles(theme => ({
//   card: {
//     minWidth: 375,
//     boxShadow: '0 3px 5px 2px rgba(105, 135, 255, .3)',
//     borderRadius: '10px',
//     padding: '0px',
//     margin: '0px'
//   },
//   title: {
//     fontSize: 16,
//     fontWeight: 'bold',
//     color: theme.palette.primary.main,
//     padding: '0px',
//     margin: '0px'
//   },

//   container: {
//     padding: '0px',
//     margin: '0px'
//   },
//   punches: {
//     color: '#46AB8'
//   }
// }))

// const CardList = ({ data }) => {
//   const classes = useStyles()
//   console.log(data)
//   function processPunches (inputData) {
//     const inputTimeFormat = 'HH:mm'
//     const minDifferenceMinutes = 20

//     const moment = require('moment') // Ensure you have Moment.js installed

//     let punches =
//       inputData &&
//       inputData.punches.map(punch => moment(punch, inputTimeFormat))
//     let inTime = punches[0]
//     let outTime = null

//     for (let i = 1; i < punches.length; i++) {
//       let diff = punches[i].diff(inTime, 'minutes')
//       if (diff >= minDifferenceMinutes) {
//         outTime = punches[i]
//         break
//       }
//     }

//     let result = {
//       date: data.date,
//       punches: []
//     }

//     if (inTime) {
//       result.punches.push(`In Time: ${inTime.format(inputTimeFormat)}`)
//     }

//     if (outTime) {
//       result.punches.push(`Out Time: ${outTime.format(inputTimeFormat)}`)
//     }

//     return result
//   }

//   React.useEffect(() => {
//     if(Object.keys(data).length > 0)
//     console.log(data && processPunches(data))
//   }, [data])

//   return (
//     <Container className={classes.container}>
//       <Grid container spacing={3}>
//         {data.map((entry, index) => (
//           <Grid item xs={12} key={index}>
//             <div>
//               <div className={classes.title} color='info' gutterBottom>
//                 Punches of {entry.date}
//               </div>
//               <Typography
//                 className={classes.title}
//                 color='textSecondary'
//                 gutterBottom
//               >
//                 <b className={classes.punches}>
//                   {entry.punches.length > 0 ? (
//                     <b style={{ color: '#46AB87' }}>
//                       {entry.punches.join(', ')}
//                     </b>
//                   ) : (
//                     <b style={{ color: '#FC5132' }}>No punches</b>
//                   )}
//                 </b>
//               </Typography>
//             </div>
//           </Grid>
//         ))}
//       </Grid>
//     </Container>
//   )
// }

// export default CardList

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { type } from 'jquery'

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 375,
    boxShadow: '0 3px 5px 2px rgba(105, 135, 255, .3)',
    borderRadius: '10px',
    padding: '10px',
    margin: '10px'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  container: {
    padding: '0px',
    margin: '0px'
  },
  punches: {
    color: '#46AB87'
  }
}))

const processPunches = inputData => {
  const inputTimeFormat = 'HH:mm'
  const minDifferenceMinutes = 20

  let punches =
    inputData && inputData.punches.map(punch => moment(punch, inputTimeFormat))
  let filteredPunches = []

  if (punches.length > 0) {
    filteredPunches.push(punches[0]) // Start with the first punch

    for (let i = 1; i < punches.length; i++) {
      let lastValidPunch = filteredPunches[filteredPunches.length - 1]
      let currentPunch = punches[i]
      let diff = currentPunch.diff(lastValidPunch, 'minutes')

      if (diff >= minDifferenceMinutes) {
        filteredPunches.push(currentPunch)
      }
    }
  }
  const result = filteredPunches.map(punch => punch.format(inputTimeFormat))

  const r = result.map((time, index) => {
    return (index % 2 === 0 ? 'In Time: ' : 'Out Time: ') + time
  })
  // Convert moments back to strings in HH:mm format
  return r
}

const CardList = ({data}) => {
 
  const classes = useStyles()
  const [processedData, setProcessedData] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    if (data && data.length > 0) {
      const result = data.map(entry => ({
        date: entry.date,
        punches: processPunches(entry),
        type: entry.type
      }))
      if (isMounted) {
        setProcessedData(result)
      }
    }
    return () => {
      isMounted = false
    }
  }, [data])

  return (
      <Container className={classes.container}>
      <Grid container spacing={3}>
        {processedData.map((entry, index) => (
          <Grid item xs={12} key={index}>
            <div>
            <b style={{ color: '#993333' }}>
                        {entry.type}
                      </b>
              <div className={classes.title} color='info' gutterBottom>
                Punches of {entry.date}
              </div>
              <Typography
                className={classes.title}
                color='textSecondary'
                gutterBottom
              >
                <b className={classes.punches}>
                  {entry.punches.length > 0 ? (
                    <b style={{ color: '#46AB87' }}>
                      {entry.punches.join(', ')}
                    </b>
                    
                  ) : (
                    <b style={{ color: '#FC5132' }}>No punches</b>
                  )}
                </b>
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default CardList
