import React, { useEffect, useRef } from "react";
import SignaturePad from "./main";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  signaturePad: {
    marginTop: theme.spacing(2),
  },
  canvasWrapper: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  signArea: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  description: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
    // custom button styles
    backgroundColor: theme.palette.info.light,
    color: "#fff",
    border: "none",
    padding: "5px 10px",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.info.light,
    },
  },
  editContainer: {
    display: "flex",
    alignItems: "center",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  hiddenButton: {
    display: "none",
  },
}));

const SignPad = ({ handleSaveClick, saveRef  }) => {
  const classes = useStyles();

  useEffect(() => {
    const wrapper = document.getElementById("signature-pad");
    const clearButton = wrapper.querySelector("[data-action=clear]");
    const undoButton = wrapper.querySelector("[data-action=undo]");
    const redoButton = wrapper.querySelector("[data-action=redo]");
    const savePNGButton = wrapper.querySelector("[data-action=save-png]");
    let undoData = [];
    const canvas = wrapper.querySelector("canvas");

    if (!canvas) {
      console.error("Canvas element not found");
      return;
    }

    const signaturePad = new SignaturePad(canvas, {
      backgroundColor: "rgb(255, 255, 255)",
    });

    function resizeCanvas() {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      console.log(canvas.offsetWidth, canvas.offsetHeight);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      signaturePad.fromData(signaturePad.toData());
    }

    window.onresize = resizeCanvas;
    resizeCanvas();

    window.addEventListener("keydown", (event) => {
      switch (true) {
        case event.key === "z" && event.ctrlKey:
          undoButton.click();
          break;
        case event.key === "y" && event.ctrlKey:
          redoButton.click();
          break;
      }
    });

    function dataURLToBlob(dataURL) {
      const parts = dataURL.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    }

    function dataURLToFile(dataURL, filename) {
      const blob = dataURLToBlob(dataURL);
      return new File([blob], filename, { type: blob.type });
    }

    function triggerSave() {
      if (signaturePad.isEmpty()) {
        alert("Please provide a signature first.");
      } else {
        const dataURL = signaturePad.toDataURL();
        const file = dataURLToFile(dataURL, "signature.png");
        handleSaveClick(file);
      }
    }

    signaturePad.addEventListener("endStroke", () => {
      undoData = [];
    });

    clearButton.addEventListener("click", () => {
      signaturePad.clear();
    });

    undoButton.addEventListener("click", () => {
      const data = signaturePad.toData();
      if (data && data.length > 0) {
        const removed = data.pop();
        undoData.push(removed);
        signaturePad.fromData(data);
      }
    });

    redoButton.addEventListener("click", () => {
      if (undoData.length > 0) {
        const data = signaturePad.toData();
        data.push(undoData.pop());
        signaturePad.fromData(data);
      }
    });

    savePNGButton.addEventListener("click", triggerSave);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return (
    <div id="signature-pad" className={`signature-pad ${classes.signaturePad}`}>
      <div
        id="canvas-wrapper"
        className={`signature-pad--body ${classes.canvasWrapper}`}
      >
        <div className={`description ${classes.description}`}>Sign Here</div>
        <canvas className={classes.signArea} />
      </div>
      <div className={`signature-pad--footer ${classes.footer}`}>
        <div className={`signature-pad--actions`}>
          <div className={classes.editContainer}>
            <button
              type="button"
              className={`button clear ${classes.button}`}
              data-action="clear"
            >
              Clear
            </button>
            <button
              type="button"
              className={`button ${classes.button}`}
              data-action="undo"
              title="Ctrl-Z"
            >
              Undo
            </button>
            <button
              type="button"
              className={`button ${classes.button}`}
              data-action="redo"
              title="Ctrl-Y"
            >
              Redo
            </button>
          </div>
          <div className={classes.column}>
            <button
              type="button"
              className={`button save ${classes.hiddenButton}`}
              data-action="save-png"
              ref={saveRef}
            >
              Save as PNG
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignPad;

// Usage example:
// const signPadRef = useRef(null);
// <SignPad ref={signPadRef} handleSaveClick={handleSaveClick} />
// To trigger save: signPadRef.current();
