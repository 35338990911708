import React, { useState } from "react";
import store from "store";
import { Box, Typography } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Components/Header";
import RegularButton from "../../../components/CustomButtons/Button";
import TypographyAutocomplete from "./Components/TypographyAutocomplete";
import useFetch from "../../../hooks/useFetch";
import { postParentConcern } from "../../../apis/parentConcerns";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 600,
    margin: "auto",
  },
  attachFile: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: "#D32F2F",
    cursor: "pointer",
    justifyContent: "flex-end",
  },
  fileLabel: {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#D32F2F",
    color: "#fff",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background 0.3s",
    "&:hover": {
      backgroundColor: "#a61b1b",
    },
  },
  submitRegularButton: {
    float: "right",
    marginTop: theme.spacing(2),
  },
}));

const initialFormState = {
  concernType: "",
  description: {
    details: { message: "" },
  },
};

export default function AcademicConcern() {
  const classes = useStyles();

  // State management
  const [formState, setFormState] = useState(initialFormState);
  const [file, setFile] = useState(null);
  const { institute, branchId, studentIds, userId } = store.get("user") || {};
  // const branchId = localStorage.getItem("user");
  // Custom hook for API calls
  const [isLoading, error, data, fetchData] = useFetch(postParentConcern);

  // Options for select
  const concernOptions = [
    "ACADEMIC_PARENT_CONCERN",
    // "GENERAL_PARENT_CONCERN",
    // "FEE_PARENT_CONCERN",
    // "TRANSPORT_PARENT_CONCERN",
  ];

  const handleChange = (e, newValue, field) => {
    const value = newValue !== undefined ? newValue : e.target.value;
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const resetForm = () => {
    setFormState(initialFormState);
    setFile(null);
    // Reset file input
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    const maxSize = 5 * 1024 * 1024;

    if (uploadedFile) {
      if (!allowedTypes.includes(uploadedFile.type)) {
        alert("Invalid file type. Only PNG, JPEG, and PDF files are allowed.");
        return;
      }
      if (uploadedFile.size > maxSize) {
        alert("File size exceeds the 5MB limit.");
        return;
      }
      setFile(uploadedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(".....................................", formState);

    if (!formState.concernType || !formState.details) {
      alert("All fields are required, including a file attachment.");
      return;
    }
    const formData = {
      description: formState.details,
      branch: branchId,
      instituteId: institute,
      requestType: formState.concernType,
      studentId: studentIds,
      status: "Open",
      academicYear: "2024-2025",
      parentId: userId,
    };

    try {
      await fetchData(formData); // Trigger API call
      alert("Form submitted successfully!");
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
    console.log(".....................................", formData);
  };

  return (
    <Box className={classes.container}>
      <Header title="Academic Concerns" path="/parentconcern" />
      <form onSubmit={handleSubmit}>
        <TypographyAutocomplete
          typographyText="Concern Type"
          options={concernOptions}
          autocompleteProps={{
            value: formState.concernType,
            onChange: (e, newValue) => handleChange(e, newValue, "concernType"),
            placeholder: "-Select-",
          }}
        />

        <TypographyAutocomplete
          className={classes.textField}
          typographyText="details of the Concern"
          useTextFieldOnly
          textFieldProps={{
            value: formState.details,
            onChange: (e) => handleChange(e, undefined, "details"),
            multiline: true,
            rows: 4,
            placeholder: "Enter detail of your concern here",
            className: classes.textField,
          }}
          options={[]}
        />

        <Box
          className={classes.attachFile}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" alignItems="center">
              <AttachFileIcon />
              <label htmlFor="file-upload" className={classes.fileLabel}>
                <input
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                Attach File Here
              </label>
            </Box>

            {file && (
              <Typography variant="body2" style={{ margin: "8px" }}>
                {file.name}
              </Typography>
            )}
          </Box> */}

          <RegularButton
            className={classes.submitRegularButton}
            variant="contained"
            type="submit"
            color="facebook"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </RegularButton>
        </Box>
      </form>
    </Box>
  );
}
