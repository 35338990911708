import React from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Container,
  Fade,
} from "@material-ui/core";
import {
  ChevronRight as ChevronRightIcon,
  ArrowBack as ArrowBackIcon,
  School as SchoolIcon,
  AttachMoney as AttachMoneyIcon,
  Help as HelpIcon,
  DirectionsBus as DirectionsBusIcon,
  History as HistoryIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
  },
  container: {
    maxWidth: 600,
    margin: "0 auto",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    marginLeft: theme.spacing(2),
    fontSize: 16,
    fontWeight: 500,
  },
  card: {
    marginBottom: theme.spacing(2),
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[4],
    },
  },
  previousConcernsCard: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${
      theme.palette.primary.light
    } 90%)`,
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    "&:last-child": {
      paddingBottom: theme.spacing(1.5),
    },
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  categoryIcon: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  historyIcon: {
    fontSize: 24,
    color: theme.palette.primary.contrastText,
  },
  contentArea: {
    flexGrow: 1,
  },
  categoryTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  categoryDescription: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  chevronIcon: {
    color: theme.palette.action.active,
    fontSize: 20,
  },
  historyChevron: {
    color: theme.palette.primary.contrastText,
    fontSize: 20,
  },
}));

const ParentConcern = () => {
  const classes = useStyles();
  const history = useHistory();

  const concernCategories = [
    {
      title: "Academic",
      description: "Questions about curriculum, homework, and performance",
      path: "/parentconcern/academic-concern",
      icon: <SchoolIcon className={classes.categoryIcon} />,
    },
    {
      title: "Fee",
      description: "Payment, refunds, and financial matters",
      path: "/parentconcern/fee-concern",
      icon: <AttachMoneyIcon className={classes.categoryIcon} />,
    },
    {
      title: "General",
      description: "Other school-related inquiries",
      path: "/parentconcern/general-concern",
      icon: <HelpIcon className={classes.categoryIcon} />,
    },
    {
      title: "Transport",
      description: "Bus routes, timing, and transportation issues",
      path: "/parentconcern/transport-concern",
      icon: <DirectionsBusIcon className={classes.categoryIcon} />,
    },
  ];

  const handleCategoryClick = (path) => {
    history.push(path);
  };

  const handleLastConcernsClick = () => {
    history.push("/parentconcern/last-concern");
  };

  return (
    <Box className={classes.root}>
      <Container className={classes.container} disableGutters>
        <Box className={classes.header}>
          <IconButton size="small" onClick={() => history.push("/home-page")}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            Parent Concern
          </Typography>
        </Box>

        <Fade in={true} timeout={500}>
          <Card
            className={`${classes.card} ${classes.previousConcernsCard}`}
            onClick={handleLastConcernsClick}
          >
            <CardContent className={classes.cardContent}>
              <Box className={classes.iconContainer}>
                <HistoryIcon className={classes.historyIcon} />
              </Box>
              <Box className={classes.contentArea}>
                <Typography
                  className={classes.categoryTitle}
                  style={{ color: "inherit" }}
                >
                  Previous Concerns
                </Typography>
              </Box>
              <ChevronRightIcon className={classes.historyChevron} />
            </CardContent>
          </Card>
        </Fade>

        <Typography className={classes.sectionTitle}>
          Raise a concern / I have an issue with:
        </Typography>

        {concernCategories.map((category, index) => (
          <Fade in={true} timeout={500 + index * 100} key={category.title}>
            <Card
              className={classes.card}
              onClick={() => handleCategoryClick(category.path)}
            >
              <CardContent className={classes.cardContent}>
                <Box className={classes.iconContainer}>{category.icon}</Box>
                <Box className={classes.contentArea}>
                  <Typography className={classes.categoryTitle}>
                    {category.title}
                  </Typography>
                  <Typography className={classes.categoryDescription}>
                    {category.description}
                  </Typography>
                </Box>
                <ChevronRightIcon className={classes.chevronIcon} />
              </CardContent>
            </Card>
          </Fade>
        ))}
      </Container>
    </Box>
  );
};

export default ParentConcern;
