import React from "react";
// mui
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
// components
import CountrySelect from "../../components/CountrySelect";

const modifyLabel = (label, values) => {
  if (label === "First Name" && !Object.keys(values).includes("lastName")) {
    return "Student Name";
  }
  return label;
};

export const renderFormField = ({
  field = {},
  handleChange,
  value,
  error,
  optionClassName,
  labelClassName,
  helperText,
  required,
  values,
  setFieldValue,
}) => {
  const {
    field: fieldName,
    type,
    label,
    options,
    getOptions,
    dependsOn,
    dependent,
  } = field;
  if (
    type === "text" ||
    type === "date" ||
    type === "email" ||
    type === "number"
  ) {
    const modifiedLabel =
      label === "First Name" ? modifyLabel(label, values) : label;

    return (
      <Box>
        <Typography variant="body2" className={labelClassName}>
          {modifiedLabel}
          {required && (
            <Typography
              color="error"
              variant="caption"
              className={labelClassName}
            >
              *
            </Typography>
          )}
        </Typography>
        <TextField
          fullWidth
          id={fieldName}
          name={fieldName}
          value={value}
          type={type}
          onChange={(e) => handleChange(fieldName, e.target.value)}
          error={error}
          // helperText={label === "Father Phone No" ? "Read Only" : helperText}
          variant="outlined"
          color="secondary"
          size="large"
          InputProps={{
            readOnly: label === "Father Phone No" ? true : false,
            required: required,
          }}
        />
        {helperText && (
          <Typography variant="caption" color="error" component="p">
            {label === "Father Phone No" ? "Read Only" : helperText}
          </Typography>
        )}
      </Box>
    );
  }

  if (type === "select") {
    return (
      <Box>
        <Typography variant="body2" className={labelClassName}>
          {label}
          {required && (
            <Typography
              color="error"
              variant="caption"
              className={labelClassName}
            >
              *
            </Typography>
          )}
        </Typography>
        <AutoComplete
          id={fieldName}
          name={fieldName}
          options={
            getOptions && dependsOn ? getOptions(values[dependsOn]) : options
          }
          value={value || null}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              color="secondary"
              error={error}
              fullWidth
            />
          )}
          onChange={(_, value) => {
            if (dependent && setFieldValue) {
              setFieldValue(dependent, "");
            }
            handleChange(fieldName, value);
          }}
          renderOption={(option) => (
            <Typography variant="button" className={optionClassName}>
              {option}
            </Typography>
          )}
        />
        {helperText && (
          <Typography variant="caption" color="error" component="p">
            {helperText}
          </Typography>
        )}
      </Box>
    );
  }

  if (type === "countrySelect") {
    return (
      <Box>
        <Typography variant="body2" className={labelClassName}>
          {label}
          {required && (
            <Typography color="error" variant="caption">
              *
            </Typography>
          )}
        </Typography>
        <CountrySelect
          id={fieldName}
          name={fieldName}
          value={value}
          handleChange={(value) => handleChange(fieldName, value)}
          error={error}
          helperText={helperText}
          variant="outlined"
          color="secondary"
        />
      </Box>
    );
  }

  return null;
};

export default renderFormField;
