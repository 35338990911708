// React and hooks
import React, { useEffect, useState, useMemo } from "react";

// External libraries
import moment from "moment";
import store from "store";

// Material-UI components and hooks
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Container from "@material-ui/core/Container";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// Custom theme and styles
import theme from "../../assets/v3/theme";
import colors from "../../assets/v3/base/colors";

// API services
import {
  getRegistrationFormConfig,
  updateProfileProgress,
} from "../../apis/UserService";

// Custom hooks
import useFetch from "../../hooks/useFetch";

// React Router
import { useHistory } from "react-router-dom";

// Context
import { useSnackbar } from "../../context/snackbar";

// Utility functions and constants
import {
  StudentDetailsFields,
  ParentDetailsFields,
  PreviousSchoolDetails,
  AddressFieldDetails,
} from "./utils";

// Custom components
import StyledBox from "../../components/StyledBox";
import TermsAndConditions from "./TermsAndConditions";
import { PageTitle } from ".";
import { hostnamesMap } from "../../components/LogoURLMap";
import ConfirmationDialog from "../../components/ConfirmationDialog.jsx";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.default,
  },
  title: {
    marginBottom: theme.spacing(2),
    textTransform: "none",
    textAlign: "left",
    fontSize: "1.2rem",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  key: {
    width: "48%",
    color: "#737373",
    flexShrink: 0,
    textTransform: "none",
    fontWeight: 400,
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  value: {
    flexGrow: 1,
    wordBreak: "break-all",
    textTransform: "none",
    color: "#171717",
    fontWeight: 600,
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  colon: {
    color: theme.palette.grey[600],
    flexShrink: 0,
  },
  heading: {
    textAlign: "left",
  },
  blueGradientBtn: {
    background: `linear-gradient(310deg, ${colors.gradients.tirumala.main}, ${
      colors.gradients.tirumala.state
    })`,
    color: "white",
  },
  blueGradientBtn2: {
    border: "1px solid transparent",
    background: `
      linear-gradient(to right, white, white), 
      linear-gradient(to right, ${colors.gradients.tirumala.state}, ${
      colors.gradients.tirumala.main
    })
    `,
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    color: colors.gradients.tirumala.state,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  white: {
    backgroundColor: "white",
    borderRadius: theme.spacing(2),
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tbody: {
    color: "#b4b4b4",
  },
  td: {
    padding: "0.125em",
    fontSize: "1rem",
  },
  tfoot: {
    borderTop: "1px solid #b4b4b4",
    fontWeight: 600,
  },
  checkbox: {
    color: colors.error.main,
    "&.Mui-checked": {
      color: colors.error.main,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoutIcon: {
    width: "26px",
    height: "26px",
  },
  hide: {
    display: "none",
  },
  notCapitalized: {
    textTransform: "none",
  },
}));

const getSuffix = (number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = number % 100;
  return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
};

const renderOptionText = (number) => {
  return (
    <> Into&nbsp;&nbsp;
      {number}
      <sup>{getSuffix(Number(number))}</sup> Class
    </>
  );
};

const ReviewPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const instituteId = hostnamesMap[window.location.hostname];
  const sid = store.get("user")._id;
  const [l1, e1, d1, f1, r1] = useFetch(getRegistrationFormConfig);
  const [l2, e2, d2, f2, r2] = useFetch(updateProfileProgress);
  const [details, setDetails] = useState({});
  const [termsChecked, setTermsChecked] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const payload = d1 ? d1.payload : null;
  const next = payload && payload.next ? payload.next : "home";
  const previous = payload && payload.previous ? payload.previous : "address";
  const branchName = d1 && d1.branchName ? d1.branchName : "";

  const fieldMap = useMemo(() => {
    return [
      ...StudentDetailsFields,
      ...ParentDetailsFields,
      ...PreviousSchoolDetails,
      ...AddressFieldDetails,
    ].reduce((map, field) => {
      map[field.field] = field;
      return map;
    }, {});
  }, []);

  const initialValues = useMemo(() => {
    return Object.keys(fieldMap).reduce((values, key) => {
      const myKey = fieldMap[key].key;
      if (myKey && details) {
        values[key] = myKey.includes(".")
          ? details[myKey.split(".")[0]]
            ? details[myKey.split(".")[0]][myKey.split(".")[1]]
            : ""
          : details[myKey];

        if (myKey === "dateOfBirth" && values[key]) {
          values[key] = moment(values[key]).format("YYYY-MM-DD");
        }
      } else {
        values[key] = "";
      }
      return values;
    }, {});
  }, [fieldMap, details]);

  useEffect(() => {
    f1({ instituteId, page: "review", sid });

    return () => {
      r1();
    };
  }, []);

  useEffect(() => {
    if (d1) {
      setDetails(d1.details);
    }
    if (e1) {
      console.error("Failed to fetch registration details:", e1);
      openSnackbar("Failed to fetch registration details", "error");
    }
  }, [d1, e1]);

  useEffect(() => {
    if (d2) {
      // openSnackbar("Profile has been saved successfully", "success", false);
      if (next === "home") {
        const settings = {
          studentAppSettings: {
            exams: true,
            profile: true,
          },
        };
        store.set("appSettings", settings);
      }
      history.push(next ? `/${next}` : "/reg-dashboard");
    }
    if (e2) {
      openSnackbar("Failed to update profile progress", "error");
    }
  }, [d2, e2]);

  const renderDetails = (title, fields) => (
    <StyledBox
      // shadow={"lg"}
      borderRadius="lg"
      marginTop={0.5}
      padding={2}
      bgColor="white"
    >
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {fields.map((field) => {
          const value = initialValues[field.field] || "";
          if (!value) return null;
          return (
            <Grid item xs={12} key={field.label}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.key}
                >
                  {field.label}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.value}
                  color="primary"
                >
                  :&nbsp;{value}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </StyledBox>
  );

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogConfirm = () => {
    setConfirmDialogOpen(false);
    f2({ sid, progress: 100 });
  };

  const handleNext = () => {
    if (termsChecked) {
      handleConfirmDialogOpen();
    } else {
      openSnackbar("Please accept the terms and conditions", "error");
    }
  };

  if (l1 || l2) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <PageTitle title="Preview" />
      <Box className={classes.mainContainer} minHeight="100vh">
        <Container maxWidth="md">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              {renderDetails("Student Details", StudentDetailsFields)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Parent Details", ParentDetailsFields)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Previous School Details", PreviousSchoolDetails)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDetails("Address Details", AddressFieldDetails)}
            </Grid>
          </Grid>

          <Box mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.push(`/${previous}`)}
              size="small"
              className={classes.blueGradientBtn2}
            >
              Previous
            </Button>
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={(e) => setTermsChecked(e.target.checked)}
                  color="info"
                  className={classes.checkbox}
                />
              }
              label={
                <Typography variant="body2" color="textPrimary">
                  I accept the <TermsAndConditions inline />
                </Typography>
              }
            />
          </Box>
          <Box marginBottom={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={handleNext}
              className={classes.blueGradientBtn}
              size="small"
            >
              Submit
            </Button>
          </Box>
        </Container>
        <ConfirmationDialog
          open={confirmDialogOpen}
          title="Confirm Details"
          subtitle="Please confirm your details before proceeding."
          handleConfirmClick={handleConfirmDialogConfirm}
          handleCancelClick={handleConfirmDialogClose}
          keyVals={{
            "Seeking Admission": details.class ? renderOptionText(details.class) : "",
            "Branch": branchName,
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default ReviewPage;
