/**
 * Class representing a Signature Event Target.
 */
class SignatureEventTarget {
  /**
   * Create a Signature Event Target.
   */
  constructor() {
    try {
      this._et = new EventTarget();
    } catch {
      // Using document as EventTarget to support iOS 13 and older.
      // Because EventTarget constructor just exists at iOS 14 and later.
      this._et = document;
    }
  }

  /**
   * Add an event listener.
   * @param {string} type - The event type.
   * @param {EventListenerOrEventListenerObject} listener - The event listener.
   * @param {boolean|AddEventListenerOptions} [options] - The options.
   */
  addEventListener(type, listener, options) {
    this._et.addEventListener(type, listener, options);
  }

  /**
   * Dispatch an event.
   * @param {Event} event - The event.
   * @returns {boolean} True if the event was dispatched, false otherwise.
   */
  dispatchEvent(event) {
    return this._et.dispatchEvent(event);
  }

  /**
   * Remove an event listener.
   * @param {string} type - The event type.
   * @param {EventListenerOrEventListenerObject} callback - The event listener.
   * @param {boolean|EventListenerOptions} [options] - The options.
   */
  removeEventListener(type, callback, options) {
    this._et.removeEventListener(type, callback, options);
  }
}

export default SignatureEventTarget;
