// React and hooks
import React, { useEffect, useRef } from "react";
import moment from "moment";
// Material-UI components
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
// Custom hooks and context
import { useSnackbar } from "../../context/snackbar";
import useFetch from "../../hooks/useFetch";
// APIs
import { getStudentCommittedFee, agreeToFee } from "../../apis/UserService";
// components
import StyledBox from "../../components/StyledBox";
// sign
import SignPad from "../../helpers/SignaturePad";

const AgreeToFee = () => {
  const [l1, e1, d1, f1, r1] = useFetch(getStudentCommittedFee);
  const [l2, e2, d2, f2, r2] = useFetch(agreeToFee);
  const user = JSON.parse(localStorage.getItem("user"));
  const { openSnackbar } = useSnackbar();
  const committedFee = d1 ? d1.payload : {};
  const status = committedFee ? committedFee.status : "";
  const amount = committedFee ? committedFee.amount : null;
  const signature = committedFee ? committedFee.sign : null;
  const signedAt = committedFee ? committedFee.agreedAt : null;
  const ref = useRef();
  const haveAmount = amount && amount > 0;

  useEffect(() => {
    f1({ sid: user._id });
    return () => {
      r1();
    };
  }, []);

  useEffect(() => {
    if (d1) {
      // handle successful response
    }
    if (e1) {
      openSnackbar("Failed to fetch committed fee", "error");
    }
  }, [d1, e1]);

  const handleAgreeToFee = () => {
    if (ref.current) {
      ref.current.click();
    } else {
      openSnackbar("Please sign to agree to fee", "error");
    }
  };

  useEffect(() => {
    if (d2) {
      openSnackbar("Successfully agreed to fee", "success");
    }
    if (e2) {
      openSnackbar("Failed to agree to fee", "error");
    }
  }, [d2, e2, openSnackbar]);

  const postFile = (file) => {
    f2({ sid: user._id, signature: file });
  };

  if (l1) {
    return (
      <StyledBox
        borderRadius="lg"
        shadow="lg"
        padding={2}
        marginTop={2}
        position="relative"
      >
        <CircularProgress />
      </StyledBox>
    );
  }

  return (
    <StyledBox
      borderRadius="lg"
      shadow="lg"
      padding={2}
      marginTop={2}
      position="relative"
    >
      <div>
        <Chip
          label={status === "AGREED" ? "Agreed" : "Pending"}
          color={status === "AGREED" ? "primary" : "secondary"}
          style={{
            backgroundColor: status === "AGREED" ? "green" : "orange",
            color: "white",
            position: "absolute",
            top: 8,
            right: 8,
          }}
        />
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          Fee Details
        </Typography>
        {haveAmount ? (
          <Typography variant="h5">
            Amount : <b style={{ color: "green" }}>{`${amount}/- INR`}</b>
          </Typography>
        ) : (
          <Typography variant="body1" style={{ color: "red" }}>
            Admin has not decided upon the fee yet
          </Typography>
        )}

        {signature ? (
          <Box marginTop={2}>
            Signature(Signed At: {moment(signedAt).format("DD-MM-YYYY hh:mm A")}):
            <img src={signature} style={{ borderRadius: 8 }} />
          </Box>
        ) : (
          <SignPad saveRef={ref} handleSaveClick={postFile} />
        )}
        {/* Render committed fee details */}
        {haveAmount && status !== "AGREED" && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAgreeToFee}
              disabled={l2}
              size="small"
            >
              {l2 ? "Submitting..." : "Agree to Fee"}
            </Button>
          </Box>
        )}
      </div>
    </StyledBox>
  );
};

export default AgreeToFee;
