import React from "react";
import Box from "@material-ui/core/Box";
import store from "store";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../assets/v3/base/colors";
import InfoIcon from "@material-ui/icons/Info";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import RefundIcon from "@material-ui/icons/MonetizationOn";
import CallIcon from "@material-ui/icons/Call";
import PolicyIcon from "@material-ui/icons/Poll";
import LogoURLMap,{hostnamesMap} from "../../components/LogoURLMap";


const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: colors.dark.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "auto",
    width: "100%",
    flexShrink: 0,
    maxHeight: "200px",
    position: "fixed",
    padding: theme.spacing(1),
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  link: {
    margin: theme.spacing(0.5, 0),
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const RegFooter = () => {
  const classes = useStyles();
  const origin = window.location.origin;
   return (
    
    <Box className={classes.footer}>
      <Box className={classes.container}>
        <Box className={classes.column}>
          {/* <Typography variant="body2" className={classes.link}>
            <InfoIcon className={classes.icon} />
            <a
              target="_blank"
             href={`${origin}/quick-links?p=about_us`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              About Us
            </a>
          </Typography> */}
           <Typography variant="body2" className={classes.link}>
           <InfoIcon className={classes.icon} />
            <a
              target="_blank"
              href={`${origin}/quick-links?p=about_us`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
             About Us
            </a>
          </Typography>
          <Typography variant="body2" className={classes.link}>
            <CallIcon className={classes.icon} />
            <a
              target="_blank"
              href={`${origin}/quick-links?p=contact_address`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Contact Us
            </a>
          </Typography>
          <Typography variant="body2" className={classes.link}>
            <PolicyIcon className={classes.icon} />
            <a
              target="_blank"
              href={`${origin}/quick-links?p=cancellation_policy`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Refund/Cancellation Policy
            </a>
          </Typography>
        </Box>
        <Box className={classes.column}>
          <Typography variant="body2" className={classes.link}>
         
            <a
              target="_blank"
              href={`${origin}/quick-links?p=privacy_policy`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Privacy Policy
            </a>
          </Typography>
 
           <Typography variant="body2" className={classes.link}>
            
          <a
          target="_blank"
          href={`${origin}/quick-links?p=terms_and_conditions`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Terms & Conditions
            </a>
            </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RegFooter;
