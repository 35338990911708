// MainComponent.js
import React from "react";
import store from "store";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./styles"; // Adjust the path as necessary
import Avatar from "./avatar"; // Adjust the path as necessary
import FeeDueAmount from "./FeeDueAmount"; // Adjust the path as necessary
import CategoryList from "./CategoryList"; // Adjust the path as necessary
import { withRouter } from "react-router-dom";

const MainComponent = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { accountType = "" } = store.get("user") || {};
  const data = store.get("appSettings") || {};

  const handleCatClick = catVal => {
    if (catVal === "exams") {
      history.push("/home");
    }
    if (catVal === "analysis") {
      history.push("/exam-analysis");
    }
    if (catVal === "classRoom") {
      history.push("/classroom");
    }
    if (catVal === "attendance") {
      history.push("/attendance");
    }
    if (catVal === "feeDetails") {
      history.push("/fee-details");
    }
    if (catVal === "profile") {
      history.push("/view-profile");
    }
    if (catVal === "progressCard") {
      history.push("/progress-card");
    }
    if (catVal === "liveClasses") {
      history.push("/video-classes");
    }
    if (catVal === "viewLibrary") {
      history.push("/assignment");
    }
    if (catVal === "PreviousClasses") {
      history.push("/previous-classes");
    }
    if (catVal === "topicWiseAnalysis") {
      history.push("/topicWiseAnalysis");
    }
    if (catVal === "pocketMoney") {
      history.push("/pocket-money");
    }
    if (catVal === "practice") {
      history.push("/practice");
    }
    if(catVal === "parentConcern") {
      history.push("/parent-concern");
    }

  };

  const handleClick = () => {
    history.push("/fee-details");
  };

  return (
    <>
      {isMobile ? <Avatar /> : null}

      <div style={{ margin: "16px" }}>
        {data &&
          data.parentAppSettings &&
          data.parentAppSettings.feeDetails &&
          accountType &&
          accountType === "parent" && (
            <FeeDueAmount handleClick={handleClick} />
          )}
        &nbsp;
        {data && <CategoryList handleCatClick={handleCatClick} data={data} />}
      </div>
    </>
  );
};

export default withRouter(MainComponent);
