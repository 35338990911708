// React imports
import React, { createContext, useContext, useState, useCallback } from "react";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [dialogName, setDialogName] = useState("");
  const [dialogData, setDialogData] = useState(null);

  const openDialog = useCallback((name, data = null) => {
    setDialogName(name);
    setDialogData(data);
  }, []);

  const closeDialog = useCallback(() => {
    setDialogName("");
    setDialogData(null);
  }, []);

  return (
    <DialogContext.Provider value={{ dialogName, dialogData, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
