import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import LogoURLMap, { hostnamesMap } from "../../components/LogoURLMap";

const HtmlPage = () => {
  const location = useLocation();
  const host = window.location.hostname;
  const instituteId = hostnamesMap[host] || "";
  const insDetails = LogoURLMap && LogoURLMap[instituteId] || {};
  if (!insDetails) return <div />;
  const search = location.search || "";
  const { p } = Object.fromEntries(new URLSearchParams(search));

  if (!p) return <div />;

  const pageUrl = insDetails[p];


  if (!pageUrl) return <div />;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
      <iframe
        src={pageUrl}
        title={insDetails.title || "Document"}
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      />
    </Box>
  );
};

export default HtmlPage;
