import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 375,
    boxShadow: '0 3px 5px 2px rgba(105, 135, 255, .3)',
    borderRadius: '10px',
    padding: '0px',
    margin: '0px'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0px',
    margin: '0px'
  },

  container: {
    padding: '0px',
    margin: '0px'
  }
}))

const CardList = ({ todayRes }) => {
  const classes = useStyles()
  const colors = {
    P: '#46AB87',
    A: '#FC5132',
    Leave: '#FFD700',
    Late: '#FFA500',
    NA: '#FC5132'
  }

  const entries = Object.entries(todayRes)
  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        {entries.map(([key, value], index) => (
          <div key={index}>
            <div className={classes.title} color='textSecondary' gutterBottom>
              {key.replace(/_/g, '-')}:{' '}
              <b style={{ color: colors[value] }}>&nbsp;{value}&nbsp;</b>
            </div>
          </div>
        ))}
      </Grid>
    </div>
  )
}

export default CardList
