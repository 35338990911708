import React, { Component } from "react";
import { withRouter } from "react-router-dom";

function authGuard(WrappedComponent) {
  return class HOC extends Component {
    render() {
      const { history } = this.props;
      const isAuthenticated = JSON.parse(localStorage.getItem("user"));
      const phase = isAuthenticated ? isAuthenticated.phase : null;

      if (window.location.pathname == "/") {
        if (
          !isAuthenticated &&
          (window.location.hostname === "tirumala.onesaz.com" ||
            window.location.hostname.includes("192.168."))
        ) {
          history.push("/reg-login");
          return null;
          // return <WrappedComponent {...this.props} />;
        }
        if (
          window.location.hostname != "onesaz.com" &&
          window.location.hostname != "www.onesaz.com" &&
          !window.location.hostname.includes("localhost")
        ) {
          if (phase === "enquiry") {
            history.push("/reg-dashboard");
          } else if (isAuthenticated) history.push("/home");
          else {
            history.push("/sign-in");
          }
          return null;
        }
        if (isAuthenticated && !isAuthenticated._id.includes("mocktest_")) {
          history.push("/home");
        }
      }

      if (!isAuthenticated && window.location.pathname != "/") {
        if (window.location.hostname === "tirumala.onesaz.com") {
          history.push("/reg-login");
          return null;
        } else {
          history.push("/sign-in");
          return null;
        }
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}

const regGuardHOC = (WrappedComponent) => {
  return class HOC extends Component {
    render() {
      const { history } = this.props;
      const isAuthenticated = JSON.parse(localStorage.getItem("user"));

      if (!isAuthenticated && window.location.pathname != "/") {
        history.push("/new-registration");
        return null;
      }
      if (isAuthenticated && window.location.pathname == "/new-registration") {
        history.push("/reg-dashboard");
        return null;
      }
      if (isAuthenticated && window.location.pathname == "/reg-login") {
        history.push("/reg-dashboard");
        return null;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
};

export const regGuard = (WrappedComponent) =>
  withRouter(regGuardHOC(WrappedComponent));

export default (WrappedComponent) => withRouter(authGuard(WrappedComponent));
