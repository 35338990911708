/* eslint-disable */
// import Button from "components/CustomButtons/Button.jsx";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Logout from "@material-ui/icons/ExitToApp";
import buttonStyle from "assets/jss/material-kit-pro-react/components/buttonStyle.jsx";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import loginImg from "assets/v2/login.png";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { useState } from "react";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import { logoutUser } from "../../apis/UserService";
import AlertComponent from "../../mycomponents/AlertComponent";
import clearLocalStorage from "../../common/clearStore";

const styles = (theme) => ({
  tryFreeButton: {
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "12px",
    textTransform: "none",
    height: "40px",
    width: "140px",
    background: "#08C5A7",
    // boxShadow: 'inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096',
    // borderRadius: '16px',
    // fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    // lineHeight: '100%',
    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    marginRight: "80px",
    "&:hover": {
      background: "#0eab8f",
    },
  },

  textFree: {
    fontWeight: "600",
    fontSize: "16px",
    // line-height: 100%;
    /* or 16px */
    color: "#FFFFFF",
  },
});
function HeaderLinks({ ...props }) {
  let isLoggedin =
    store.get("user") != undefined &&
    store.get("user")._id &&
    !store.get("user")._id.includes("mocktest_");
  function home(type) {
    const { history } = props;
    store.set("user", { _id: "mock_" });
    history.push("/home", { mockuser: true, testtype: type });
  }

  function login() {
    const { history } = props;
    history.push("/sign-in");
  }

  function register() {
    const { history } = props;
    history.push("/register");
  }
  const redirectToPlans = () => {
    const { history } = props;
    history.push("/plans");
  };
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertText, setAlertText] = useState("");

  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    setAlertText("");
  };
  async function logout() {
    const exam = store.get("startedExam");
    if (exam && exam.test_status === "STARTED") {
      setConfirmOpen(true);
      setAlertText(
        `Please, Submit Test : ${exam.testDisplayName} Before Logging Out!`
      );
    } else {
      const user = store.get("user");
      const _id = user ? user._id : "";
      const phase = user ? user.phase : "";
      await logoutUser({ id: _id });
      clearLocalStorage();
      if (phase && phase === "enquiry") {
        history.push("/reg-login");
      } else {
        history.push("/sign-in");
      }
    }
  }
  const [mockOpen, setMockOpen] = useState(false);
  function openMockTests() {
    setMockOpen(!mockOpen);
  }
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { classes, dropdownHoverColor } = props;
  return (
    <List
      className={classes.list + " " + classes.mlAuto}
      style={{ padding: "8px 0px" }}
    >
      <Hidden smDown>
        {/* <CustomDropdown

          style={{ background: 'blue !important' }}
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Mock Test"
          buttonProps={{
            className: classes.navLink,
            color: "primary"
          }}
          hoverColor="default"
          buttonIcon={Assignment}
          dropdownList={[
            <span onClick={() => home('JEE MAINS')} className={classes.dropdownLink}>
              JEE MAIN
              </span>,
            <span onClick={() => home('JEE ADVANCED')} className={classes.dropdownLink}>
              JEE ADVANCE
              </span>,
            <span onClick={() => home('NEET')} className={classes.dropdownLink}>
              NEET
              </span>
          ]}>
        </CustomDropdown> */}
        {/* <ListItem>  */}
        {!isLoggedin ? (
          <React.Fragment>
            {/* <Button
              onClick={() => redirectToPlans()}
              style={{ textTransform: "none", width: "120px" }}
            >
              <span>Plans & Pricing</span>
            </Button> */}
            <Button
              onClick={() => {
                const { history } = props;
                history.push("/about-us")
              }}
              style={{ textTransform: "none", width: "120px" }}
            >
              <span>About us</span>
            </Button>
            {/* <Button
              // onClick={() => redirectToPlans()}
              style={{ textTransform: "none", width: "120px" }}
            >
              <span>LMS</span>
            </Button>
            <Button
              // onClick={() => redirectToPlans()}
              style={{ textTransform: "none", width: "120px" }}
            >
              <span>FEE PLAN</span>
            </Button> */}
            <Button
              onClick={() => login()}
              style={{ textTransform: "none", width: "130px" }}
            >
              <span>
                <img src={loginImg} /> Log In
              </span>
            </Button>
            <Link to="/register">
              <Button className={classes.tryFreeButton}>Sign up</Button>
            </Link>
          </React.Fragment>
        ) : (
          <Typography
            style={{ margin: "0px 40px" }}
            variant="h5"
            component="h5"
            onClick={logout}
          >
            <Logout />
            {/* Logout &nbsp; */}
          </Typography>
        )}
      </Hidden>

      <Hidden mdUp>
        {!isLoggedin ? (
          <React.Fragment>
            {/* <ListItem>
              <Typography
                style={{ fontSize: "18px" }}
                variant="h5"
                component="h5"
              >
                <span onClick={() => redirectToPlans()}>Plans & Pricing</span>
              </Typography>
            </ListItem> */}
            <ListItem>
              <Typography
                style={{ fontSize: "18px" }}
                variant="h5"
                component="h5"
                onClick={() => login()}
              >
                Login
              </Typography>
            </ListItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ListItem>
              <Typography
                style={{ fontSize: "18px" }}
                variant="h5"
                component="h5"
                onClick={logout}
              >
                Logout &nbsp;
              </Typography>{" "}
              <Logout />
            </ListItem>
          </React.Fragment>
        )}
        <Divider variant="middle" />
      </Hidden>
      {/* mdUp  */}
      <Hidden mdUp>
        {/* <ListItem>
               <Typography variant="h5" component="h5" onClick = {()=> login()} >
                 Login
               </Typography>
            </ListItem> */}

        {/*<ListItem button onClick={openMockTests}>
          <ListItemText primary="Mock Tests" />
          {mockOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
         <Collapse in={mockOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => home('JEE MAINS')}>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText inset primary="JEE MAIN" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => home('JEE ADVANCED')}>
              <ListItemIcon>
                <SendIcon />
             
              </ListItemIcon>
              <ListItemText inset primary="JEE ADVANCE" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => home('NEET')}>
              <ListItemIcon>
                <SendIcon />
              
              </ListItemIcon>
              <ListItemText inset primary="NEET" />
            </ListItem>
          </List>
        </Collapse> */}
        <Link to="/register">
          <Button className={classes.tryFreeButton}>Sign up</Button>
        </Link>
        <Divider variant="middle" />
      </Hidden>
      {/* mdUp end  */}
      <AlertComponent open={confirmOpen} closeAlert={handleCloseAlertBox}>
        {alertText}
      </AlertComponent>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "success",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};

export default compose(
  withRouter,
  withStyles(headerLinksStyle),
  withStyles(buttonStyle),
  withStyles(styles)
)(HeaderLinks);
