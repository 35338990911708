import axios from "axios";
import API_CONST from "../constants";
import { history } from "../index";
import store from "store";
var baseAxios = axios.create({
  baseURL: API_CONST.EXAM_SERVER_URL,
  //port : API_CONST.PORT,
  //timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'},
  headers: { "Content-type": "application/json" },

  withCredentials: true,
});

//request interceptor
baseAxios.interceptors.request.use(
  (request) => {
    // console.log();
    const token = store.get("user") ? store.get("user").token : "login"; //token to be provided here
    if (token && !request.url.includes("domain_config")) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
    // console.log({ requestInterceptor: request });
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
baseAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log(err.response.data);
    if(err.response.config.url.includes("/reg-login")){
      return err.response;
    }
    const errorData = err.response.data;
    if (err.response.status === 404) {
      throw new Error(`${err.config.url} not found`);
    }
    if (err.response.status === 440) {
      history.push("/session-expired", { ...errorData });
      history.go();
    }
    if (err.response.status === 450) {
      history.push("/exam-expired", { ...errorData });
      history.go();
    }
    throw err;
  }
);

export default baseAxios;
