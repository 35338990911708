/**
 * Interface for point data structure used e.g. in SignaturePad#fromData method
 * @typedef {Object} BasicPoint
 * @property {number} x
 * @property {number} y
 * @property {number} pressure
 * @property {number} time
 */

/**
 * Class representing a point.
 * @implements {BasicPoint}
 */
class Point {
  /**
   * Create a point.
   * @param {number} x - The x coordinate.
   * @param {number} y - The y coordinate.
   * @param {number} [pressure=0] - The pressure.
   * @param {number} [time=Date.now()] - The time.
   * @throws Will throw an error if x or y is not a number.
   */
  constructor(x, y, pressure = 0, time = Date.now()) {
    if (isNaN(x) || isNaN(y)) {
      throw new Error(`Point is invalid: (${x}, ${y})`);
    }
    this.x = +x;
    this.y = +y;
    this.pressure = pressure;
    this.time = time;
  }

  /**
   * Calculate the distance to another point.
   * @param {BasicPoint} start - The starting point.
   * @returns {number} The distance to the starting point.
   */
  distanceTo(start) {
    return Math.sqrt(
      Math.pow(this.x - start.x, 2) + Math.pow(this.y - start.y, 2)
    );
  }

  /**
   * Check if this point is equal to another point.
   * @param {BasicPoint} other - The other point.
   * @returns {boolean} True if the points are equal, false otherwise.
   */
  equals(other) {
    return (
      this.x === other.x &&
      this.y === other.y &&
      this.pressure === other.pressure &&
      this.time === other.time
    );
  }

  /**
   * Calculate the velocity from another point.
   * @param {BasicPoint} start - The starting point.
   * @returns {number} The velocity from the starting point.
   */
  velocityFrom(start) {
    return this.time !== start.time
      ? this.distanceTo(start) / (this.time - start.time)
      : 0;
  }
}

export default Point;
