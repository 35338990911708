import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    margin:"16px 0px",

  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  iconButton: {
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "28px",
  },
});

function LeftArrowWithTitle({ classes, title, path = "/", className, style }) {
  return (
    <Grid className={`${classes.container} ${className}`} style={style}>
      <Link to={{ pathname: path }} className={classes.link}>
        <ArrowBackIcon className={classes.iconButton} />
      </Link>
      <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
    </Grid>
  );
}

LeftArrowWithTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default withStyles(styles)(LeftArrowWithTitle);
