import axios from "./examServerAxios";
import { adminAxios } from "./baseAxios";

const COMMON = "/parent-concerns";


export async function getParentConcerns(body) {
  try {
    return (await adminAxios.get(`/parent-portal/${COMMON}`,   {params: { ...body }}, ))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function postParentConcern(body) {
  try {
    return (await adminAxios.post("`/parent-portal/${COMMON}`", { ...body }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
