import { Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import AlertComponent from "./mycomponents/AlertComponent";
import { storeRedux } from "./redux/store/store";

import Chrome from "assets/img/chrome.png";
import Edge from "assets/img/edge.png";

// Routes
import SlowInternetDetector from "./mycomponents/SlowSpeedDetect";
import Routes from "./routes/Routes";

const App = () => {
  const supportedBrowser = {
    Edge: true,
    Chrome: true,
    CriOS: true,
    Safari: true,
  };
  const [confirmOpen, setConfirmOpen] = useState(true);
  const handleCloseAlertBox = () => {
    setConfirmOpen(true);
  };

  /* getting browser name and version */
  const getBrowserDetails = () => {
    let userAgent = navigator.userAgent,
      tem,
      M =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = userAgent.match(/\bOPR\/(\d+)/);
      if (tem != null || window.navigator.userAgent.indexOf("Opera") > -1) {
        return { name: "Opera", version: tem[1] };
      }
    }
    if (
      window.navigator.userAgent.indexOf("Edg") > -1 ||
      window.navigator.userAgent.indexOf("Edge") > -1
    ) {
      tem = userAgent.match(/\Edg\/(\d+)/);
      if (tem != null) {
        return { name: "Edge", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: +M[1],
    };
  };
  const browser = getBrowserDetails();

  const canDisableRightClick = () => {
    if (window.location.hostname === "localhost") {
      return false;
    }
    if (window.location.hostname.includes("192.168.")) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    // Disable Right-Click
    const disableRightClick = (e) => {
      if (canDisableRightClick()) {
        e.preventDefault();
      }
    };

    // Disable F12 and other dev tools shortcuts
    const disableDevTools = (e) => {
      if (!canDisableRightClick()) {
        return;
      }
      // Disable F12
      if (e.key === "F12") {
        e.preventDefault();
      }
      // Disable Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
      if (e.ctrlKey && e.shiftKey && ["I", "C", "J"].includes(e.key)) {
        e.preventDefault();
      }
      // Disable Ctrl+U (View Source)
      if (e.ctrlKey && e.key === "u") {
        e.preventDefault();
      }
    };

    // Add event listeners
    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("keydown", disableDevTools);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableDevTools);
    };
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      {browser.name in supportedBrowser ? (
        <Provider store={storeRedux}>
          <Router>
            <Routes />
          </Router>
          <SlowInternetDetector />
        </Provider>
      ) : (
        <AlertComponent
          open={confirmOpen}
          closeAlert={handleCloseAlertBox}
          btnText=""
        >
          <WarningRoundedIcon fontSize="large" color="error" />
          <Typography color="error">Browser not supported!</Typography>
          <Typography variant="body2">
            Please switch to{" "}
            <a href="https://www.google.com/intl/en_in/chrome/">
              <img src={Chrome} alt="Chrome" />
            </a>{" "}
            or{" "}
            <a href="https://www.microsoft.com/en-us/edge">
              <img src={Edge} alt="Edge" />
            </a>{" "}
            and try again.
          </Typography>
        </AlertComponent>
      )}
    </React.Fragment>
  );
};
export default App;
