import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const SlowInternetDetector = () => {
  const [isSlow, setIsSlow] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const checkInternetSpeed = () => {
    if (navigator.connection) {
      const { downlink } = navigator.connection;

      const speedInKbps = downlink * 1024;

      if (speedInKbps < 6) {
        if (!isSlow) {
          setIsSlow(true);
          setMessage("Your internet connection is very slow.");
          setOpenSnackbar(true);
        }
      } else {
        if (isSlow) {
          setIsSlow(false);
          setMessage("Your internet speed is back to normal.");
          setOpenSnackbar(true);
        }
      }
    }
  };

  useEffect(() => {
    checkInternetSpeed();

    if (navigator.connection) {
      navigator.connection.addEventListener("change", checkInternetSpeed);
    }

    return () => {
      if (navigator.connection) {
        navigator.connection.removeEventListener("change", checkInternetSpeed);
      }
    };
  }, [isSlow]);

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={isSlow ? null : 6000}
        onClose={() => (isSlow ? null : setOpenSnackbar(false))}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={isSlow ? "warning" : "success"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SlowInternetDetector;
