// React and Formik
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";

// State management
import store from "store";

// Material-UI components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import CircularProgress from "@material-ui/core/CircularProgress";

// Custom components
import OtpInput from "./OtpInput";
import StyledBox from "../../components/StyledBox";
import RegFooter from "./RegFooter";

// Assets
import IndFlag from "../../assets/svgs/ind.svg";
import LogoURLMap, { hostnamesMap } from "../../components/LogoURLMap";

// Custom hooks
import useFetch from "../../hooks/useFetch";

// APIs
import { createOtpLogin, newRegLogin } from "../../apis/UserService";
import { getAppSettingsInfo } from "../../apis/appSettings";

// Context
import { useSnackbar } from "../../context/snackbar";

// form validation schema
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must be a number")
    .min(10, "Phone number must be 10 characters")
    .max(10, "Phone number must be 10 characters")
    .required("Phone number is required"),
});

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 500,
    textAlign: "left",
  },
  label: {
    lineHeight: "1.5",
    marginBottom: theme.spacing(1),
  },
  inputWithIcon: {
    border: "none",
  },
  back: {
    position: "absolute",
    top: 8,
    left: 8,
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  helperText: {
    marginLeft: theme.spacing(1),
  },
  relative: {
    position: "relative",
  },
}));

const RegistrationLogInPage = () => {
  const [l1, e1, d1, f1, r1] = useFetch(createOtpLogin);
  const [l2, e2, d2, f2, r2] = useFetch(newRegLogin);
  const [otpSent, setOtpSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const payload = d1 ? d1.payload : null;
  const instituteId =
    hostnamesMap[window.location.hostname] || "5d679d49c136660a09596d85";
  const stuff = LogoURLMap[instituteId] || {};

  const [resendTimer, setResendTimer] = useState(90);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isFetchingSettings, setIsFetchingSettings] = useState(false);

  const clear = () => {
    setOtpSent(false);
    r1();
    r2();
  };

  const submitOtp = (values) => {
    const data = {
      phoneNumber,
      otp: values.otp,
    };
    f2(data);
  };

  const resendOtp = () => {
    f1({ phoneNumber });
    setOtpSent(true);
    setResendTimer(90);
    setIsResendDisabled(true);
  };

  useEffect(() => {
    if (d1) {
      if (d1.data.status === "FAILURE") {
        openSnackbar(d1.data.message, "error");
      } else {
        openSnackbar("OTP sent successfully", "success");
        setOtpSent(true);
      }
    }
    if (e1) {
      openSnackbar("Failed to send OTP", "error");
    }

    return () => {
      r1();
    };
  }, [d1, e1, openSnackbar]);

  useEffect(() => {
    if (d2) {
      if(d2 && d2.data && d2.data.user) {
        openSnackbar("Login successful", "success");
        localStorage.setItem("isAuthenticated", true);
        store.set("user", d2.data.user);
        const settings = {
          studentAppSettings: {
            exams: true,
            profile: true,
          },
        };
        store.set("appSettings", settings);
        history.push(`/${d2.data.route}`);
      } else if (d2 && d2.data && d2.data.status === "FAILURE") {
        openSnackbar(d2.data.message, "error");
      } else if (d2 && d2.data && d2.data.message) {
        openSnackbar(d2.data.message, "warning", false);
      } else {
        openSnackbar("Something went wrong", "error", false);
      }
    }
    if (e2) {
      openSnackbar(e2, "error");
    }

    return () => {
      r2();
    };
  }, [d2, e2, openSnackbar]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    let timer;
    if (otpSent) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpSent]);

  const formatTime = (seconds) => {
    const duration = moment.duration(seconds, "seconds");
    return `${duration.minutes()}:${duration
      .seconds()
      .toString()
      .padStart(2, "0")}`;
  };

  const classes = useStyles();

  if (isFetchingSettings) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100%"
      bgcolor="background.default"
    >
      <StyledBox
        padding={4}
        borderRadius="lg"
        shadow="lg"
        bgColor="white"
        className={classes.relative}
      >
        {otpSent ? (
          <Box>
            <Box maxWidth={400} margin="auto" mt={2} mb={2}>
              <Button
                onClick={() => clear()}
                className={classes.back}
                size="small"
                variant="text"
              >
                <ArrowBackIosRoundedIcon />
                &nbsp;Back
              </Button>
              <Typography variant="h6" gutterBottom>
                Verify OTP
              </Typography>
              <Typography variant="body2">
                Please enter the 6-digit verification code sent to you at +91-
                {phoneNumber}
              </Typography>
            </Box>
            <Formik
              initialValues={{ otp: "" }}
              validationSchema={Yup.object().shape({
                otp: Yup.string()
                  .matches(/^[0-9]+$/, "OTP must be a number")
                  .min(6, "OTP is required")
                  .max(6, "OTP is required")
                  .required("OTP is required"),
              })}
              onSubmit={(values) => {
                submitOtp(values);
              }}
            >
              {({ errors, touched, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <Box maxWidth={400} margin="auto">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <OtpInput
                          handleOtpChange={(otp) => {
                            setFieldValue("otp", otp);
                          }}
                        />
                        {touched.otp && errors.otp && (
                          <Typography color="error" variant="caption">
                            {errors.otp}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mt={1}
                        >
                          <Button
                            onClick={resendOtp}
                            color="primary"
                            disabled={isResendDisabled}
                            variant="text"
                          >
                            {isResendDisabled
                              ? `Resend OTP in ${formatTime(resendTimer)}`
                              : "Resend OTP"}
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={l2}
                          >
                            {l2 ? "Verifying OTP..." : "Submit OTP"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        ) : (
          <>
            <Box textAlign="center">
              {stuff && (
                <img src={stuff.url} alt="Institute Logo" height="120" />
              )}
            </Box>
            <Formik
              initialValues={{ phoneNumber: phoneNumber }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setPhoneNumber(values.phoneNumber);
                f1(values);
              }}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Box maxWidth={320} margin="auto">
                      <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.title}
                      >
                        Log In
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            component="p"
                            className={classes.label}
                          >
                            Phone Number
                          </Typography>
                          <TextField
                            fullWidth
                            id="phoneNumber"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.phoneNumber && Boolean(errors.phoneNumber)
                            }
                            helperText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    gridGap={10}
                                  >
                                    <Box>
                                      <img
                                        src={IndFlag}
                                        width="40"
                                        alt="india flag"
                                      />
                                    </Box>
                                    <Typography variant="button">
                                      +91
                                    </Typography>
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            textAlign="right"
                            className={classes.buttonContainer}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={l1}
                            >
                              {l1 ? "Sending OTP..." : "Verify Number"}
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box textAlign="center" mt={2}>
                            <Typography variant="body2">
                              Don't have an account?{" "}
                              <Link
                                to="/new-registration"
                                style={{ textDecoration: "none" }}
                              >
                                Register
                              </Link>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </StyledBox>
      <RegFooter />
    </Box>
  );
};

export default RegistrationLogInPage;
