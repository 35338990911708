import pinkAanalysis from "../assets/img/navigationBarAssets/pinkAanalysis.svg";
import greenExamAanalysis from "../assets/img/navigationBarAssets/greenExamAnalysis.svg";
import feeDetails from "../assets/img/navigationBarAssets/feeDetails.svg";
import myClassroom from "../assets/img/navigationBarAssets/myClassroom.svg";
import attendance from "../assets/img/navigationBarAssets/attendance.svg";
import profileYellow from "../assets/img/navigationBarAssets/profileYellow.svg";
import progressCard from "../assets/img/navigationBarAssets/progressCard.svg";
import TopicWiseAnalysis from "../assets/img/navigationBarAssets/topicWiseAnalysis.svg";
import liveClasses from "../assets/img/navigationBarAssets/liveClasses.svg";
import library from "../assets/img/navigationBarAssets/library.svg";
import store from "store";
import restrictedLevel from "../views/SignIn/restrictedLevel";

const user = store.get("user") || {};
const batch = user && user.level ? user.level : "";

export const parentNavList = [
  {
    title: "View Exams",
    icon: pinkAanalysis,
    value: "exams",
    path: "/home"
  },
  {
    title: "Exam Analysis",
    icon: greenExamAanalysis,
    value: "analysis",
    path: "/exam-analysis"
  },
  {
    title: "My Classroom",
    icon: myClassroom,
    value: "classRoom",
    path: "/classroom"
  },
  {
    title: "Meetings",
    icon: liveClasses,
    value: "meetings",
    path: "/video-classes"
  },
  {
    title: "View Attendance",
    icon: attendance,
    value: "attendance",
    path: "/attendance"
  },
  {
    title: "Fee Details",
    icon: feeDetails,
    value: "feeDetails",
    path: "/fee-details"
  },
  {
    title: "Pocket Money",
    icon: feeDetails,
    value: "pocketMoney",
    path: "/pocket-money"
  },

  {
    title: "Progress Card",
    icon: progressCard,
    value: "progressCard",
    path: "/progress-card"
  },
  {
    title: "Practice",
    icon: liveClasses,
    value: "practice",
    path: "/practice"
  },
  {
    title: "My Profile",
    icon: profileYellow,
    value: "profile",
    // path: "/view-profile",
    path: "/profile"
  },
  {
    title: "Parent Concern",
    icon: profileYellow,
    value: "parentconcern",
    path: "/parentconcern"
  }
];

export const studentNavList = restrictedLevel.includes(`${batch}`)
  ? [
      {
        title: "View Exams",
        icon: pinkAanalysis,
        value: "exams",
        path: "/home"
      },
      {
        title: "My Profile",
        icon: profileYellow,
        value: "profile",
        path: "/profile"
      }
    ]
  : [
      {
        title: "View Exams",
        icon: pinkAanalysis,
        value: "exams",
        path: "/home"
      },
      {
        title: "Exam Analysis",
        icon: greenExamAanalysis,
        value: "analysis",
        path: "/exam-analysis"
      },
      {
        title: "My Classroom",
        icon: myClassroom,
        value: "classRoom",
        path: "/classroom"
      },
      {
        title: "View Attendance",
        icon: attendance,
        value: "attendance",
        path: "/attendance"
      },
      // {
      //   title: "Fee Details",
      //   icon: feeDetails,
      //   value: "feeDetails",
      //   path: "/fee-details",
      // },
      {
        title: "Progress Card",
        icon: progressCard,
        value: "progressCard",
        path: "/progress-card"
      },
      {
        title: "Live Classes",
        icon: liveClasses,
        value: "liveClasses",
        path: "/video-classes"
      },
      {
        title: "View Library",
        icon: library,
        value: "viewLibrary",
        path: "/assignment"
      },
      {
        title: "Topic Wise Analysis",
        icon: TopicWiseAnalysis,
        value: "topicWiseAnalysis",
        path: "/topicWiseAnalysis"
      },
      {
        title: "Previous Classes",
        icon: liveClasses,
        value: "PreviousClasses",
        path: "/previous-classes"
      },
      {
        title: "My Profile",
        icon: profileYellow,
        value: "profile",
        // path: "/view-profile",
        path: "/profile"
      },
      {
        title: "Practice",
        icon: liveClasses,
        value: "practice",
        path: "/practice"
      },
      {
        title: "Parent Concern",
        icon: profileYellow,
        value: "parentconcern",
        path: "/parentconcern"
      }
    ];
