import examAxios from "apis/examServerAxios";
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import Advance from "./Advance";
import "./App.css";
import CustomTypePanel from "./CustomTypePanel";
import HomePanel from "./HomePanel";
import NetworkProgress from "./NetworkProgress";

class ExamPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: null,
      testData: store.get("examObject"),
      warningGiven: false, // Add state to track if warning has been given
    };
    this.props = props;
  }

  isLoggedin = false;
  testname = null;
  startedTime = 0;
  remainingTime = 0;
  id = store.get("user")._id;
  loadingMsg = "Loading...";

  submit = (reason = "") => {
    const { testData } = this.state;
    const student_id = store.get("user")._id;
    const test_name = testData.test_name;

    let body = JSON.stringify({
      student_id: student_id,
      test_name: test_name,
      reason,
    });
    // console.log({ body });
    examAxios.post("/exams/submittest", body).then((val) => {
      console.log(val);
      examAxios
        .post("/exams/results", body)
        .then((respResults) => {
          console.log(respResults);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleVisibilityChange = () => {
    const isCognizantOrligadepatil =
      window.location.hostname === "cognizant.onesaz.com" ||
      window.location.hostname === "ligadepatil.onesaz.com";

    if (isCognizantOrligadepatil && document.hidden) {
      if (!this.state.warningGiven) {
        alert(
          "Tab switching is not allowed during the exam. If you switch tabs again, your exam will be automatically submitted."
        );
        this.setState({ warningGiven: true });
      } else {
        alert(
          "Tab switching is not allowed during the exam. Submitting exam automatically."
        );
        this.submit("TAB_SWITCHED");
        window.location.href = "/home";
      }
    }
  };

  componentDidMount() {
    const isCognizantOrligadepatil =
      window.location.hostname === "cognizant.onesaz.com" ||
      window.location.hostname === "ligadepatil.onesaz.com";

    // Fetch schema if test data exists
    if (this.state.testData && this.state.testData.test_name) {
      const url = `/exams/getSchema?testName=${this.state.testData.test_name}`;
      examAxios
        .get(url)
        .then((res) => res.data.output)
        .then((res) => {
          this.setState({
            schema: res,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // Add visibility change event listener only for cognizant and ligadepatil
    if (isCognizantOrligadepatil) {
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    }
  }

  componentWillUnmount() {
    // Remove visibility change event listener
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  render() {
    const { testData } = this.state;
    if (testData && testData.test_type.includes("CUSTOM") && testData.pdfLink) {
      return (
        <Redirect
          to={{
            pathname: "/writePDFtest",
            search: `?examDuration=${testData.test_duration}&user=${
              this.id
            }&test_type=${testData.test_type}&displayname=${
              testData.testDisplayName
            }&examName=${testData.test_name}&pdfLink=${testData.pdfLink}`,
          }}
        />
      );
    }

    return this.remainingTime >= 0 && testData ? (
      <React.Fragment>
        {testData.test_type &&
        (testData.test_type.includes("JEE ADVANCED") ||
          (this.state.schema && this.state.schema.questions)) ? (
          <Advance
            paper={testData.paper}
            examDuration={testData.test_duration}
            user={this.id}
            displayName={testData.testDisplayName}
            examName={testData.test_name}
            schema={this.state.schema}
          />
        ) : testData.test_type.includes("CUSTOM") ? (
          <CustomTypePanel
            examDuration={testData.test_duration}
            user={this.id}
            test_type={testData.test_type}
            displayname={testData.testDisplayName}
            examName={testData.test_name}
          />
        ) : (
          <HomePanel
            examDuration={testData.test_duration}
            user={this.id}
            test_type={testData.test_type}
            displayname={testData.testDisplayName}
            examName={testData.test_name}
          />
        )}
      </React.Fragment>
    ) : (
      <NetworkProgress />
    );
  }
}

export default compose(withRouter)(ExamPortal);
