/**
 * The main idea and some parts of the code (e.g. drawing variable width Bézier curve) are taken from:
 * http://corner.squareup.com/2012/07/smoother-signatures.html
 *
 * Implementation of interpolation using cubic Bézier curves is taken from:
 * https://web.archive.org/web/20160323213433/http://www.benknowscode.com/2012/09/path-interpolation-using-cubic-bezier_9742.html
 *
 * Algorithm for approximated length of a Bézier curve is taken from:
 * http://www.lemoda.net/maths/bezier-length/index.html
 */

import Bezier from "./Bezier";
import Point from "./Point";
import SignatureEventTarget from "./SignatureEventTarget";
import throttle from "./Throttle";

class SignaturePad extends SignatureEventTarget {
  constructor(canvas, options = {}) {
    super();
    this.canvas = canvas;
    this._ctx = canvas.getContext("2d", this.canvasContextOptions);

    this.velocityFilterWeight = options.velocityFilterWeight || 0.7;
    this.minWidth = options.minWidth || 0.5;
    this.maxWidth = options.maxWidth || 2.5;
    this.throttle = options.throttle || 16; // in milliseconds
    this.minDistance = options.minDistance || 5; // in pixels
    this.dotSize = options.dotSize || 0;
    this.penColor = options.penColor || "black";
    this.backgroundColor = options.backgroundColor || "rgba(0,0,0,0)";
    this.compositeOperation = options.compositeOperation || "source-over";
    this.canvasContextOptions = options.canvasContextOptions || {};

    // Internal state tracking
    this._drawingStroke = false;
    this._isEmpty = true;
    this._lastPoints = [];
    this._data = [];
    this._lastVelocity = 0;
    this._lastWidth = 0;

    this._strokeMoveUpdate = this.throttle
      ? throttle(SignaturePad.prototype._strokeUpdate, this.throttle)
      : SignaturePad.prototype._strokeUpdate;

    this.clear();
    // Enable mouse and touch event handlers
    this.on();
  }

  clear() {
    const { _ctx: ctx, canvas } = this;

    // Clear canvas using background color
    ctx.fillStyle = this.backgroundColor;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    this._data = [];
    this._reset(this._getPointGroupOptions());
    this._isEmpty = true;
  }

  fromDataURL(dataUrl, options = {}) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      const ratio = options.ratio || window.devicePixelRatio || 1;
      const width = options.width || this.canvas.width / ratio;
      const height = options.height || this.canvas.height / ratio;
      const xOffset = options.xOffset || 0;
      const yOffset = options.yOffset || 0;

      this._reset(this._getPointGroupOptions());

      image.onload = () => {
        this._ctx.drawImage(image, xOffset, yOffset, width, height);
        resolve();
      };
      image.onerror = (error) => {
        reject(error);
      };
      image.crossOrigin = "anonymous";
      image.src = dataUrl;

      this._isEmpty = false;
    });
  }

  toDataURL(type = "image/png", encoderOptions) {
    switch (type) {
      case "image/svg+xml":
        if (typeof encoderOptions !== "object") {
          encoderOptions = undefined;
        }
        return `data:image/svg+xml;base64,${btoa(this.toSVG(encoderOptions))}`;
      default:
        if (typeof encoderOptions !== "number") {
          encoderOptions = undefined;
        }
        return this.canvas.toDataURL(type, encoderOptions);
    }
  }

  toBlob(type = "image/png", encoderOptions) {
    return new Promise((resolve) => {
      this.canvas.toBlob((blob) => {
        resolve(blob);
      }, type, encoderOptions);
    });
  }

  on() {
    // Disable panning/zooming when touching canvas element
    this.canvas.style.touchAction = 'none';
    this.canvas.style.msTouchAction = 'none';
    this.canvas.style.userSelect = 'none';

    const isIOS = /Macintosh/.test(navigator.userAgent) && 'ontouchstart' in document;

    if (window.PointerEvent && !isIOS) {
      this._handlePointerEvents();
    } else {
      this._handleMouseEvents();

      if ('ontouchstart' in window) {
        this._handleTouchEvents();
      }
    }
  }

  off() {
    // Enable panning/zooming when touching canvas element
    this.canvas.style.touchAction = 'auto';
    this.canvas.style.msTouchAction = 'auto';
    this.canvas.style.userSelect = 'auto';

    this.canvas.removeEventListener('pointerdown', this._handlePointerDown);
    this.canvas.removeEventListener('mousedown', this._handleMouseDown);
    this.canvas.removeEventListener('touchstart', this._handleTouchStart);

    this._removeMoveUpEventListeners();
  }

  _getListenerFunctions() {
    const canvasWindow =
      window.document === this.canvas.ownerDocument
        ? window
        : this.canvas.ownerDocument.defaultView || this.canvas.ownerDocument;

    return {
      addEventListener: canvasWindow.addEventListener.bind(canvasWindow),
      removeEventListener: canvasWindow.removeEventListener.bind(canvasWindow),
    };
  }

  _removeMoveUpEventListeners() {
    const { removeEventListener } = this._getListenerFunctions();
    removeEventListener('pointermove', this._handlePointerMove);
    removeEventListener('pointerup', this._handlePointerUp);

    removeEventListener('mousemove', this._handleMouseMove);
    removeEventListener('mouseup', this._handleMouseUp);

    removeEventListener('touchmove', this._handleTouchMove);
    removeEventListener('touchend', this._handleTouchEnd);
  }

  isEmpty() {
    return this._isEmpty;
  }

  fromData(pointGroups, { clear = true } = {}) {
    if (clear) {
      this.clear();
    }

    this._fromData(
      pointGroups,
      this._drawCurve.bind(this),
      this._drawDot.bind(this),
    );

    this._data = this._data.concat(pointGroups);
  }

  toData() {
    return this._data;
  }

  _isLeftButtonPressed(event, only) {
    if (only) {
      return event.buttons === 1;
    }

    return (event.buttons & 1) === 1;
  }

  _pointerEventToSignatureEvent(event) {
    return {
      event: event,
      type: event.type,
      x: event.clientX,
      y: event.clientY,
      pressure: 'pressure' in event ? event.pressure : 0,
    };
  }

  _touchEventToSignatureEvent(event) {
    const touch = event.changedTouches[0];
    return {
      event: event,
      type: event.type,
      x: touch.clientX,
      y: touch.clientY,
      pressure: touch.force,
    };
  }

  _handleMouseDown = (event) => {
    if (!this._isLeftButtonPressed(event, true) || this._drawingStroke) {
      return;
    }
    this._strokeBegin(this._pointerEventToSignatureEvent(event));
  };

  _handleMouseMove = (event) => {
    if (!this._isLeftButtonPressed(event, true) || !this._drawingStroke) {
      // Stop when not pressing primary button or pressing multiple buttons
      this._strokeEnd(this._pointerEventToSignatureEvent(event), false);
      return;
    }

    this._strokeMoveUpdate(this._pointerEventToSignatureEvent(event));
  };

  _handleMouseUp = (event) => {
    if (this._isLeftButtonPressed(event)) {
      return;
    }

    this._strokeEnd(this._pointerEventToSignatureEvent(event));
  };

  _handleTouchStart = (event) => {
    if (event.targetTouches.length !== 1 || this._drawingStroke) {
      return;
    }

    // Prevent scrolling.
    if (event.cancelable) {
      event.preventDefault();
    }

    this._strokeBegin(this._touchEventToSignatureEvent(event));
  };

  _handleTouchMove = (event) => {
    if (event.targetTouches.length !== 1) {
      return;
    }

    // Prevent scrolling.
    if (event.cancelable) {
      event.preventDefault();
    }

    if (!this._drawingStroke) {
      this._strokeEnd(this._touchEventToSignatureEvent(event), false);
      return;
    }

    this._strokeMoveUpdate(this._touchEventToSignatureEvent(event));
  };

  _handleTouchEnd = (event) => {
    if (event.targetTouches.length !== 0) {
      return;
    }

    if (event.cancelable) {
      event.preventDefault();
    }

    this.canvas.removeEventListener('touchmove', this._handleTouchMove);

    this._strokeEnd(this._touchEventToSignatureEvent(event));
  };

  _handlePointerDown = (event) => {
    if (!event.isPrimary || !this._isLeftButtonPressed(event) || this._drawingStroke) {
      return;
    }

    event.preventDefault();

    this._strokeBegin(this._pointerEventToSignatureEvent(event));
  };

  _handlePointerMove = (event) => {
    if (!event.isPrimary) {
      return;
    }
    if (!this._isLeftButtonPressed(event, true) || !this._drawingStroke) {
      // Stop when primary button not pressed or multiple buttons pressed
      this._strokeEnd(this._pointerEventToSignatureEvent(event), false);
      return;
    }

    event.preventDefault();
    this._strokeMoveUpdate(this._pointerEventToSignatureEvent(event));
  };

  _handlePointerUp = (event) => {
    if (!event.isPrimary || this._isLeftButtonPressed(event)) {
      return;
    }

    event.preventDefault();
    this._strokeEnd(this._pointerEventToSignatureEvent(event));
  };

  _getPointGroupOptions(group) {
    return {
      penColor: group && 'penColor' in group ? group.penColor : this.penColor,
      dotSize: group && 'dotSize' in group ? group.dotSize : this.dotSize,
      minWidth: group && 'minWidth' in group ? group.minWidth : this.minWidth,
      maxWidth: group && 'maxWidth' in group ? group.maxWidth : this.maxWidth,
      velocityFilterWeight:
        group && 'velocityFilterWeight' in group
          ? group.velocityFilterWeight
          : this.velocityFilterWeight,
      compositeOperation:
        group && 'compositeOperation' in group
          ? group.compositeOperation
          : this.compositeOperation,
    };
  }

  _strokeBegin(event) {
    const cancelled = !this.dispatchEvent(
      new CustomEvent('beginStroke', { detail: event, cancelable: true }),
    );
    if (cancelled) {
      return;
    }

    const { addEventListener } = this._getListenerFunctions();
    switch (event.event.type) {
      case 'mousedown':
        addEventListener('mousemove', this._handleMouseMove);
        addEventListener('mouseup', this._handleMouseUp);
        break;
      case 'touchstart':
        addEventListener('touchmove', this._handleTouchMove);
        addEventListener('touchend', this._handleTouchEnd);
        break;
      case 'pointerdown':
        addEventListener('pointermove', this._handlePointerMove);
        addEventListener('pointerup', this._handlePointerUp);
        break;
      default:
      // do nothing
    }

    this._drawingStroke = true;

    const pointGroupOptions = this._getPointGroupOptions();

    const newPointGroup = {
      ...pointGroupOptions,
      points: [],
    };

    this._data.push(newPointGroup);
    this._reset(pointGroupOptions);
    this._strokeUpdate(event);
  }

  _strokeUpdate(event) {
    if (!this._drawingStroke) {
      return;
    }

    if (this._data.length === 0) {
      // This can happen if clear() was called while a signature is still in progress,
      // or if there is a race condition between start/update events.
      this._strokeBegin(event);
      return;
    }

    this.dispatchEvent(
      new CustomEvent('beforeUpdateStroke', { detail: event }),
    );

    const point = this._createPoint(event.x, event.y, event.pressure);
    const lastPointGroup = this._data[this._data.length - 1];
    const lastPoints = lastPointGroup.points;
    const lastPoint =
      lastPoints.length > 0 && lastPoints[lastPoints.length - 1];
    const isLastPointTooClose = lastPoint
      ? point.distanceTo(lastPoint) <= this.minDistance
      : false;
    const pointGroupOptions = this._getPointGroupOptions(lastPointGroup);

    // Skip this point if it's too close to the previous one
    if (!lastPoint || !(lastPoint && isLastPointTooClose)) {
      const curve = this._addPoint(point, pointGroupOptions);

      if (!lastPoint) {
        this._drawDot(point, pointGroupOptions);
      } else if (curve) {
        this._drawCurve(curve, pointGroupOptions);
      }

      lastPoints.push({
        time: point.time,
        x: point.x,
        y: point.y,
        pressure: point.pressure,
      });
    }

    this.dispatchEvent(new CustomEvent('afterUpdateStroke', { detail: event }));
  }

  _strokeEnd(event, shouldUpdate = true) {
    this._removeMoveUpEventListeners();

    if (!this._drawingStroke) {
      return;
    }

    if (shouldUpdate) {
      this._strokeUpdate(event);
    }

    this._drawingStroke = false;
    this.dispatchEvent(new CustomEvent('endStroke', { detail: event }));
  }

  _handlePointerEvents() {
    this._drawingStroke = false;

    this.canvas.addEventListener('pointerdown', this._handlePointerDown);
  }

  _handleMouseEvents() {
    this._drawingStroke = false;

    this.canvas.addEventListener('mousedown', this._handleMouseDown);
  }

  _handleTouchEvents() {
    this.canvas.addEventListener('touchstart', this._handleTouchStart);
  }

  _reset(options) {
    this._lastPoints = [];
    this._lastVelocity = 0;
    this._lastWidth = (options.minWidth + options.maxWidth) / 2;
    this._ctx.fillStyle = options.penColor;
    this._ctx.globalCompositeOperation = options.compositeOperation;
  }

  _createPoint(x, y, pressure) {
    const rect = this.canvas.getBoundingClientRect();

    return new Point(
      x - rect.left,
      y - rect.top,
      pressure,
      new Date().getTime(),
    );
  }

  _addPoint(point, options) {
    const { _lastPoints } = this;

    _lastPoints.push(point);

    if (_lastPoints.length > 2) {
      // To reduce the initial lag make it work with 3 points
      // by copying the first point to the beginning.
      if (_lastPoints.length === 3) {
        _lastPoints.unshift(_lastPoints[0]);
      }

      // _points array will always have 4 points here.
      const widths = this._calculateCurveWidths(
        _lastPoints[1],
        _lastPoints[2],
        options,
      );
      const curve = Bezier.fromPoints(_lastPoints, widths);

      // Remove the first element from the list, so that there are no more than 4 points at any time.
      _lastPoints.shift();

      return curve;
    }

    return null;
  }

  _calculateCurveWidths(startPoint, endPoint, options) {
    const velocity =
      options.velocityFilterWeight * endPoint.velocityFrom(startPoint) +
      (1 - options.velocityFilterWeight) * this._lastVelocity;

    const newWidth = this._strokeWidth(velocity, options);

    const widths = {
      end: newWidth,
      start: this._lastWidth,
    };

    this._lastVelocity = velocity;
    this._lastWidth = newWidth;

    return widths;
  }

  _strokeWidth(velocity, options) {
    return Math.max(options.maxWidth / (velocity + 1), options.minWidth);
  }

  _drawCurveSegment(x, y, width) {
    const ctx = this._ctx;

    ctx.moveTo(x, y);
    ctx.arc(x, y, width, 0, 2 * Math.PI, false);
    this._isEmpty = false;
  }

  _drawCurve(curve, options) {
    const ctx = this._ctx;
    const widthDelta = curve.endWidth - curve.startWidth;
    // '2' is just an arbitrary number here. If only length is used, then
    // there are gaps between curve segments :/
    const drawSteps = Math.ceil(curve.length()) * 2;

    ctx.beginPath();
    ctx.fillStyle = options.penColor;

    for (let i = 0; i < drawSteps; i += 1) {
      // Calculate the Bezier (x, y) coordinate for this step.
      const t = i / drawSteps;
      const tt = t * t;
      const ttt = tt * t;
      const u = 1 - t;
      const uu = u * u;
      const uuu = uu * u;

      let x = uuu * curve.startPoint.x;
      x += 3 * uu * t * curve.control1.x;
      x += 3 * u * tt * curve.control2.x;
      x += ttt * curve.endPoint.x;

      let y = uuu * curve.startPoint.y;
      y += 3 * uu * t * curve.control1.y;
      y += 3 * u * tt * curve.control2.y;
      y += ttt * curve.endPoint.y;

      const width = Math.min(
        curve.startWidth + ttt * widthDelta,
        options.maxWidth,
      );
      this._drawCurveSegment(x, y, width);
    }

    ctx.closePath();
    ctx.fill();
  }

  _drawDot(point, options) {
    const ctx = this._ctx;
    const width =
      options.dotSize > 0
        ? options.dotSize
        : (options.minWidth + options.maxWidth) / 2;

    ctx.beginPath();
    this._drawCurveSegment(point.x, point.y, width);
    ctx.closePath();
    ctx.fillStyle = options.penColor;
    ctx.fill();
  }

  _fromData(pointGroups, drawCurve, drawDot) {
    for (const group of pointGroups) {
      const { points } = group;
      const pointGroupOptions = this._getPointGroupOptions(group);

      if (points.length > 1) {
        for (let j = 0; j < points.length; j += 1) {
          const basicPoint = points[j];
          const point = new Point(
            basicPoint.x,
            basicPoint.y,
            basicPoint.pressure,
            basicPoint.time,
          );

          if (j === 0) {
            this._reset(pointGroupOptions);
          }

          const curve = this._addPoint(point, pointGroupOptions);

          if (curve) {
            drawCurve(curve, pointGroupOptions);
          }
        }
      } else {
        this._reset(pointGroupOptions);
        drawDot(
          new Point(points[0].x, points[0].y, points[0].pressure, points[0].time),
          pointGroupOptions,
        );
      }
    }
  }

  toSVG({ includeBackgroundColor = false } = {}) {
    const pointGroups = this._data;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const minX = 0;
    const minY = 0;
    const maxX = this.canvas.width / ratio;
    const maxY = this.canvas.height / ratio;
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    svg.setAttribute('width', this.canvas.width.toString());
    svg.setAttribute('height', this.canvas.height.toString());
    svg.setAttribute('viewBox', `${minX} ${minY} ${this.canvas.width} ${this.canvas.height}`);
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    if (includeBackgroundColor && this.backgroundColor) {
      const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      rect.setAttribute('x', minX.toString());
      rect.setAttribute('y', minY.toString());
      rect.setAttribute('width', maxX.toString());
      rect.setAttribute('height', maxY.toString());
      rect.setAttribute('fill', this.backgroundColor);
      svg.appendChild(rect);
    }

    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.setAttribute('fill', 'none');
    group.setAttribute('stroke', this.penColor);
    group.setAttribute('stroke-width', (this.maxWidth * 2.25).toString());
    group.setAttribute('stroke-linecap', 'round');
    group.setAttribute('stroke-linejoin', 'round');
    group.setAttribute('transform', `scale(${1 / ratio})`);
    svg.appendChild(group);

    for (const pointGroup of pointGroups) {
      const { points } = pointGroup;

      if (points.length > 1) {
        for (let j = 0; j < points.length; j += 1) {
          const basicPoint = points[j];
          const point = new Point(
            basicPoint.x,
            basicPoint.y,
            basicPoint.pressure,
            basicPoint.time,
          );

          if (j === 0) {
            this._reset(pointGroup);
          }

          const curve = this._addPoint(point, pointGroup);

          if (curve) {
            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            const attr = `M ${curve.startPoint.x.toFixed(3)},${curve.startPoint.y.toFixed(3)} `
              + `C ${curve.control1.x.toFixed(3)},${curve.control1.y.toFixed(3)} `
              + `${curve.control2.x.toFixed(3)},${curve.control2.y.toFixed(3)} `
              + `${curve.endPoint.x.toFixed(3)},${curve.endPoint.y.toFixed(3)}`;

            path.setAttribute('d', attr);
            path.setAttribute('stroke-width', (curve.endWidth * 2.25).toFixed(3));
            group.appendChild(path);
          }
        }
      } else {
        const dot = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        const { x, y } = points[0];
        const dotSize = pointGroup.dotSize > 0
          ? pointGroup.dotSize
          : (pointGroup.minWidth + pointGroup.maxWidth) / 2;
        dot.setAttribute('r', dotSize.toString());
        dot.setAttribute('cx', x.toString());
        dot.setAttribute('cy', y.toString());
        dot.setAttribute('fill', pointGroup.penColor);
        group.appendChild(dot);
      }
    }

    return new XMLSerializer().serializeToString(svg);
  }
}

export default SignaturePad;
