import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import onesaz from "assets/v2/onesaz.png";
import login from "assets/v2/login.png";
// import Company from "views/SectionsPage/FooterComponenets/Company";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import twitter from "./Sections/logos/twitter.svg";
import facebook from "./Sections/logos/faceboook.svg";
import youtube from "./Sections/logos/youTube.svg";
import instagram from "./Sections/logos/instagram.svg";
import CallMadeIcon from "@material-ui/icons/CallMade";
import { set } from "store";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  landingFooter: {
    // background: "#FAFAFA",
    height: "200px",
    color: "#FAFAFA",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },
  button: {
    "&:hover $arrowIcon": {
      display: "inline-block",
      color: "blue",
      height: "18px",
      width: "18px",
      fontWeight: 700,
    },
    "&:hover $linksText": {
      color: "#415B8C", // Change this to the color you want for the text
      fontWeight: 700,
    },
    "&:hover": {
      color: "#415B8C",
    },
  },
  arrowIcon: {
    display: "none",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#FAFAFA",
    textAlign: "left",
  },
  linksText: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: "13px",
    color: "#FAFAFA",
    textAlign: "left",
  },
  footerSeperator: {
    minHeight: "2px",
    // background: "#EFF2F4",
    background: "black", // Change this to the color you want for the text
    borderRadius: "5px",
    margin: "0px 20px",
  },
  footerTop: {
    display: "flex",
    minHeight: "100px",
    height: "100px",
    paddingLeft: "40px",
  },

  footerBottom: {
    height: "100px",
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  copyRight: {
    // color: "rgba(49, 69, 106, 0.7)",
    color: "#FAFAFA",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "180%",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px",
      marginTop: "40px",
    },
  },
  ftop: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    // color: "rgba(49, 69, 106, 0.7)",
    color: "#FAFAFA",
    textTransform: "initial",
  },
});

function Footer(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const [headingText, setHeading] = React.useState("");
  const [descriptionText, setDescription] = React.useState("");
  const handleClickOpen = (heading, description) => {
    setOpen(true);
    setHeading(heading);
    setDescription(description);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Grid
        container
        className={classes.landingFooter}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} className={classes.footerTop}>
          <Grid item xs={12} sm={6} style={{ paddingLeft: 4, marginLeft: 4 }}>
            <Link to="/">
              <Box display="flex" flexDirection="row" alignItems="center">
                <img
                  style={{
                    width: 40,
                    height: 40,
                    color: "#FAFAFA",
                    margin: 0,
                  }}
                  src="https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png"
                  alt="logo"
                />
                <Typography variant="h5" style={{ color: "#FFFF" }}>
                  Onesaz
                </Typography>
              </Box>

              <br />

              <Typography className={classes.text}>
                Onesaz – where both LMS and ERP come together in a fantastic
              </Typography>
              <Typography className={classes.text}>
                fusion. Let's simplify education, together!
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.ftop}
                >
                  Company
                </Link>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button>
                <Link
                  to="/FooterAboutUs"
                  style={{ textDecoration: "none" }}
                  className={classes.ftop}
                >
                  About us
                </Link>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linksText}
                  onClick={() =>
                    handleClickOpen(
                      "Product",
                      "Transform education with our all-in-one LMS & ERP solutions, designed to streamline administrative tasks, enhance learning experiences, and provide actionable insights for educational institutes. Our LMS offers course management, content authoring, assessment tools, communication features, and detailed analytics. The ERP system manages student information, finances, human resources, admissions, and academic planning. Benefit from increased efficiency, enhanced learning, data-driven decisions, scalability, and seamless integration. Ready to revolutionize your institution? Contact us today to schedule a demo or request more information!"
                    )
                  }
                >
                  Product
                </Link>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linksText}
                >
                  contact
                </Link>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.ftop}
                >
                  support
                </Link>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linksText}
                >
                  FAQ
                </Link>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linksText}
                >
                  Privacy Policy
                </Link>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linksText}
                >
                  Terms of Service
                </Link>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.ftop}
                >
                  Our Work
                </Link>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linksText}
                >
                  Pricing
                </Link>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linksText}
                >
                  Customer
                </Link>
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              <Button className={classes.button}>
                <Link to="/" className={classes.linksText}>
                  Product
                </Link>
                <CallMadeIcon className={classes.arrowIcon} />
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        <br />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.footerSeperator} />
      <Grid item xs={12} sm={12} md={12} className={classes.footerBottom}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={10}>
            <div className={classes.copyRight}>
              {/* &copy; ACADHUB EDU TECH PRIVATE LIMITED. All rights */}
              {/* reserved. */}
              &copy; WE-THINK EDU TECH PRIVATE LIMITED. All rights reserved.
              <br />
              <Link to="/sign-in">
                <Button
                  style={{ textTransform: "none" }}
                  className={classes.ftop}
                >
                  <span>
                    <img src={login} alt="logo" /> Log In
                  </span>
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={0.5} sm={0.5}>
            <img src={youtube} />
          </Grid>
          &nbsp; &nbsp;
          <Grid item xs={0.5} sm={0.5}>
            <img src={twitter} />
          </Grid>
          <Grid item xs={0.5} sm={0.5}>
            <img src={facebook} />
          </Grid>
          <Grid item xs={0.5} sm={0.5}>
            <img src={instagram} />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{headingText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {descriptionText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default withStyles(styles)(Footer);
