import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import store from "store";

import compose from "recompose/compose";
import clearLocalStorage from "../common/clearStore";
import AlertComponent from "./AlertComponent";
var moment = require("moment");
function SessionExpired(props) {
  const { history } = props;
  const [confirmOpen, setConfirmOpen] = useState(true);
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);

    const user = store.get("user");
    const phase = user ? user.phase : "";
    clearLocalStorage();
    if (phase && phase === "enquiry") {
      history.push("/reg-login");
    } else {
      history.push("/sign-in");
    }
  };
  const errorData = props.location.state;
  return (
    <React.Fragment>
      <AlertComponent open={confirmOpen} closeAlert={handleCloseAlertBox}>
        {props.location.state.message} Login again!
        <br />
        Last Login At:{" "}
        {moment(errorData.lastLogin).format("DD-MMM-YYYY hh:mm:ss a")}
        <br />
        {errorData && errorData.device && (
          <Fragment>
            Device used: <strong>{errorData.device}</strong> &nbsp; &nbsp;
          </Fragment>
        )}
        {errorData && errorData.device && (
          <Fragment>Browser: {errorData.browser}</Fragment>
        )}
      </AlertComponent>
    </React.Fragment>
  );
}

export default compose(withRouter)(SessionExpired);
