import { useMemo, useEffect, useState } from "react";
import { ThemeProvider, Typography, IconButton, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import UpdateIcon from "@material-ui/icons/Update";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import theme from "../assets/theme";
import newTheme from "../assets/v3/theme";
import colors from "../assets/v3/base/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // ... keeping all existing styles ...
  agree: {
    backgroundColor: colors.success.main,
    color: colors.white.main,
    boxShadow: 0,
    "&:hover": {
      backgroundColor: colors.success.main,
    },
  },
  disagree: {
    backgroundColor: colors.error.main,
    color: colors.white.main,
    boxShadow: 0,
    "&:hover": {
      backgroundColor: colors.error.main,
    },
  },
  confirm: {
    backgroundColor: colors.info.main,
    color: colors.white.main,
    boxShadow: 0,
    "&:hover": {
      backgroundColor: colors.info.main,
    },
  },
  cancel: {
    backgroundColor: colors.grey[500],
    color: colors.white.main,
    boxShadow: 0,
    "&:hover": {
      backgroundColor: colors.grey[500],
    },
  },
  save: {
    backgroundColor: colors.primary.main,
    color: colors.white.main,
    boxShadow: 0,
    "&:hover": {
      backgroundColor: colors.primary.main,
    },
  },
  delete: {
    backgroundColor: colors.error.main,
    color: colors.white.main,
    boxShadow: 0,
    "&:hover": {
      backgroundColor: colors.error.main,
    },
  },
  error: {
    color: colors.error.main,
  },
  warning: {
    color: colors.warning.main,
  },
  succ: {
    color: colors.success.main,
  },
  success: {
    backgroundColor: colors.success.main,
    color: colors.white.main,
    boxShadow: 0,
    "&:hover": {
      backgroundColor: colors.success.main,
    },
  },
  update: {
    color: colors.info.main,
  },
  login: {
    backgroundColor: colors.info.main,
    color: colors.white.main,
  },
  dialogStyles: {
    minHeight: 300,
    borderRadius: 10,
    padding: 20,
    position: 'relative',
  },
  contentCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridGap: 20,
  },
  title: {
    color: colors.dark.main,
  },
  head: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: colors.white.main,
  },
  progressBarSuccess: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.success.main,
    },
  },
  progressBarError: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.error.main,
    },
  },
  progressBarWarning: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.warning.main,
    },
  },
  progressBarUpdate: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.info.main,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertComponent = ({
  children,
  open,
  closeAlert,
  alertType,
  btnText = "Close",
  ifThemeRequired = true,
  newTheme: useNewTheme = false,
  customActions = [],
  actionsPosition = "flex-end",
  showCloseIcon = false,
  autoClose = false,
  duration = 5000,
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(100);

  const myTheme = useMemo(() => {
    if (ifThemeRequired) {
      if (useNewTheme) {
        return newTheme;
      }
      return theme;
    }
    return null;
  }, [ifThemeRequired, useNewTheme]);

  useEffect(() => {
    let timer;
    if (open && autoClose) {
      setProgress(100);
      const startTime = Date.now();
      const endTime = startTime + duration;

      timer = setInterval(() => {
        const now = Date.now();
        const remaining = endTime - now;
        const progressValue = (remaining / duration) * 100;

        if (remaining <= 0) {
          clearInterval(timer);
          closeAlert("auto");
        } else {
          setProgress(progressValue);
        }
      }, 10);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [open, autoClose, duration, closeAlert]);

  const alertStyles = {
    error: {
      title: "Error!",
      className: classes.error,
      Icon: ErrorOutlineOutlinedIcon,
      progressClass: classes.progressBarError,
    },
    warning: {
      title: "Warning!",
      className: classes.warning,
      Icon: WarningOutlinedIcon,
      progressClass: classes.progressBarWarning,
    },
    success: {
      title: "Success!",
      className: classes.succ,
      Icon: CheckCircleTwoToneIcon,
      progressClass: classes.progressBarSuccess,
    },
    update: {
      title: "Update!",
      className: classes.update,
      Icon: UpdateIcon,
      progressClass: classes.progressBarUpdate,
    },
  };

  const { title, className, Icon, progressClass } = alertStyles[alertType] || {};

  return (
    <ThemeProvider theme={myTheme}>
      <Dialog
        open={open}
        fullWidth
        onClose={closeAlert}
        aria-labelledby="confirm-dialog"
        TransitionComponent={Transition}
        classes={{ paper: classes.dialogStyles }}
        maxWidth="xs"
      >
        {showCloseIcon && (
          <IconButton
            aria-label="close"
            onClick={() => closeAlert("manual")}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent className={classes.contentCenter}>
          {title && (
            <Box className={classes.head}>
              <Icon fontSize="large" className={className} />
              <Typography variant="h6" className={className}>
                {title}
              </Typography>
            </Box>
          )}
          <Typography variant="body1" align="center" className={classes.title}>
            {children}
          </Typography>
          <Box
            display="flex"
            justifyContent={actionsPosition}
            alignItems="center"
            gridGap={10}
          >
            {customActions.length > 0 ? (
              customActions.map((action, index) => (
                <Button
                  key={index}
                  onClick={() => {
                    action.onClick();
                    if (action.closeOnClick) {
                      closeAlert("manual");
                    }
                  }}
                  variant={action.variant || "contained"}
                  className={classes[action.className] || ""}
                >
                  {action.text}
                </Button>
              ))
            ) : (
              <Button onClick={() => closeAlert("manual")} className={classes.cancel}>
                {btnText}
              </Button>
            )}
          </Box>
        </DialogContent>
        {autoClose && (
          <LinearProgress
            variant="determinate"
            value={progress}
            className={`${classes.progressBar} ${progressClass}`}
          />
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default AlertComponent;