import React from "react";
import { hostnamesMap } from "../../components/LogoURLMap";
import { Container, Typography, Box } from "@material-ui/core";

const CancellationPolicy = () => {
  const instituteId = hostnamesMap[window.location.hostname];
  if (instituteId === "5fd25b12ed14200ff702f738") {
    return (
      <Container>
        <Box mt={3}>
          <Typography variant="h3">Cancellation Policy</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">Tirumala IIT & Medical Academy Cancellation Policy</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h4">Refunds</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">Once paid, it will not be refunded.</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h4">Cancellations</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">No cancellations are allowed.</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h4">Contact</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">For any queries, contact our support team.</Typography>
        </Box>
      </Container>
    );
  }
  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h1">Cancellation Policy</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          Our cancellation policy is designed to be fair and transparent.
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          We are based in {hostnamesMap[window.location.hostname] || "unknown"}.
        </Typography>
      </Box>
    </Container>
  );
}

export default CancellationPolicy;
