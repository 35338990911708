import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Person } from "@material-ui/icons";
import React, { useEffect } from "react";
import store from "store";
import { getUser } from "../../apis/UserService";
import useFetch from "../../hooks/useFetch";
import NetworkProgress from "../NetworkProgress";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function UserAccountCard({ id, ...props }) {
  const [loading, err, userData, fetchUser] = useFetch(getUser);

  const classes = useStyles();

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  const handleChildrenAccount = () => {
    const user = store.get("user");
    store.set("user", {
      ...user,
      studentIds: userData._id,
      name: userData.fullName,
      classVal: userData.class,
      branchId: userData.branch,
      level: userData.batch,
      sectionid: userData.section,
    });
    props.history.push("/home-page");
  };
  return (
    <>
      {loading ? (
        <NetworkProgress />
      ) : (
        <>
          {userData && userData._id && (
            <ListItem button onClick={handleChildrenAccount}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <Person />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`Admission No: ${userData.admissionNo}`}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {userData.fullName}
                    </Typography>
                    {` — Branch: ${userData.branch} and Class: ${
                      userData.class
                    }`}
                  </>
                }
              />
            </ListItem>
          )}
        </>
      )}
    </>
  );
}
