import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
// theme
import theme from "../../assets/v3/theme";
import colors from "../../assets/v3/base/colors";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    backgroundColor: colors.background,
  },
  dialogTitle: {
    "& h2": {
      color: colors.dark.main,
    },
  },
  title: {
    color: colors.dark.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  subTitle: {
    color: colors.secondary.main,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  dialogContent: {
    color: colors.text.main,
  },
  dialogActions: {
    // justifyContent: 'center',
  },
  cancelButton: {
    margin: theme.spacing(1),
    color: colors.secondary.main,
    border: `1px solid ${colors.secondary.main}`,
    backgroundColor: colors.background.default,
    "&:hover": {
      backgroundColor: colors.background.default,
    },
  },
  confirmButton: {
    margin: theme.spacing(1),
    color: colors.white.main,
    backgroundColor: colors.success.main,
    "&:hover": {
      backgroundColor: colors.success.main,
    },
  },
  tCell: {
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
    borderBottom: "none",
  },
  myKey: {
    color: colors.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
      maxWidth: "100px",
    },
  },
  myValue: {
    color: colors.dark.main,
    fontWeight: "bold",
    wordBreak: "break-all",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
}));

const ConfirmationDialog = ({
  open,
  title,
  subtitle,
  confirmationText,
  handleConfirmClick,
  handleCancelClick,
  renderContent,
  data,
  keyVals,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleCancelClick}
        classes={{ paper: classes.dialog }}
        fullScreen={isMobile}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="subtitle2" className={classes.subTitle}>
              {subtitle}
            </Typography>
            <br />
            {confirmationText ? (
              <Typography variant="body2" className={classes.subTitle}>
                {confirmationText}
              </Typography>
            ) : null}
          </DialogContentText>
          {renderContent ? renderContent(data) : null}
          {keyVals && (
            <TableContainer>
              <Table>
                <TableBody>
                  {Object.entries(keyVals).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tCell}
                      >
                        <Typography variant="body2" className={classes.myKey}>
                          {key}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tCell}>
                        <Typography variant="body2" className={classes.myValue}>
                          : {value}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleCancelClick}
            className={classes.cancelButton}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            className={classes.confirmButton}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  confirmationText: PropTypes.string.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  renderContent: PropTypes.func,
  data: PropTypes.any,
  keyVals: PropTypes.object,
};

export default ConfirmationDialog;
