import React from "react";
import { hostnamesMap } from "../../components/LogoURLMap";
import { Container, Typography, Box } from "@material-ui/core";

const AboutUsNew = () => {
  const instituteId = hostnamesMap[window.location.hostname];
  if (instituteId === "5fd25b12ed14200ff702f738") {
    return (
      <Container>
        <Box mt={3}>
          <Typography variant="h3">About Us</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">Welcome to Tirumala IIT & Medical Academy</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h3">20,000</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">Intermediate students</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h3">1000</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">TIMA Faculty and Staff</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h3">30k</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">TIMA Alumni Worldwide</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h4">Intermediate (+2) Education</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h3">90%</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">success rate</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h3">Top 10</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">Colleges that Create Futures</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h3">No. 1</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">in the Godavari Districts</Typography>
        </Box>
      </Container>
    );
  }
  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h1">About Us</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          We are a team of developers who love to build websites and applications.
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          We are based in Hyderabad .
        </Typography>
      </Box>
    </Container>
  );
}

export default AboutUsNew;