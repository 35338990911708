import React from "react";
import { hostnamesMap } from "../../components/LogoURLMap";
import { Container, Typography, Box } from "@material-ui/core";

const ContactUs = () => {
  const instituteId = hostnamesMap[window.location.hostname];
  if (instituteId === "5fd25b12ed14200ff702f738") {
    return (
      <Container>
        <Box mt={3}>
          <Typography variant="h3">Contact Us</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">Tirumala IIT & Medical Academy</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h4">Rajamahendravaram</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">
            Katheru, Rajamahendravaram.<br />
            Ph: 888 614 7777, 888 664 2298<br />
            0883 – 297 00 77, 297 00 88
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h4">Visakhapatnam</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">
            N.H-16, R. Tallavalasa, Visakhapatnam.<br />
            Ph: 9133332 103, 104, 105
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h4">Bhimavaram</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">
            Near Prakruthi Ashram, Bhimavaram.<br />
            Ph: 9133332 101, 9949742103
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h4">Tanuku</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">
            Komaravaram, Iragavaram Road, Tanuku.<br />
            Ph: 73866 20777, 73966 15777
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h4">Payakaraopeta</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">
            P.L. Puram Centre, Beside N.H – 16, Payakaraopeta.<br />
            Ph: 80084 78777, 85209 26777
          </Typography>
        </Box>
      </Container>
    );
  }
  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h1">Contact Us</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          We are a team of developers who love to build websites and applications.
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          You can reach us at contact@developers.com.
        </Typography>
      </Box>
    </Container>
  );
}

export default ContactUs;
