import React from "react";

const SiteLoader = () => {
  return (
    <div>
      <iframe
        src="https://stuladmin.onesaz.com/"
        title="Site Loader"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
        }}
      />
    </div>
  );
};

export default SiteLoader;
