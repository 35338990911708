import React, { useEffect, useState } from "react";
// mui
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
// apis
import { getPreviousTestDetails } from "../../apis/UserService";
// hooks
import { useSnackbar } from "../../context/snackbar";
import useFetch from "../../hooks/useFetch";
// assets
import colors from "../../assets/v3/base/colors";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loadingBox: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    minHeight: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainBox: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    marginTop: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: "white",
  },
  heading1: {
    color: colors.dark.main,
    fontWeight: "bold",
  },
  testName: {
    color: colors.secondary.main,
    fontWeight: "medium",
  }
}));

const PrevTestDetails = ({ sid, history }) => {
  const classes = useStyles();
  const { openSnackbar } = useSnackbar();
  const [l0, e0, d0, f0, r0] = useFetch(getPreviousTestDetails);
  const [testsHistory, setTestHistory] = useState([]);

  useEffect(() => {
    if (sid && history.length > 0) {
      f0({ sid });
    }
  }, [sid, history]);

  useEffect(() => {
    if (d0) {
      if (d0.data.status === "SUCCESS") {
        setTestHistory(d0.data.tests);
      } else if (d0.data.status === "FAILURE") {
        openSnackbar(d0.data.message, "error", false);
      }
    }
    if (e0) {
      openSnackbar("Failed to fetch previous test details", "error");
    }

    return () => {
      r0();
    };
  }, [d0, e0]);

  if (l0) {
    return (
      <Box className={classes.loadingBox}>
        <CircularProgress />
      </Box>
    );
  }

  if (testsHistory && testsHistory.length > 0) {
    return (
      <Box className={classes.mainBox}>
        <Typography variant="body2" className={classes.heading1}>
          Previous
        </Typography>
        <Grid container spacing={2}>
          {testsHistory.map((test, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Box className={classes.card}>
                  <Typography variant="body2" className={classes.testName}>
                    {test.test_name}
                  </Typography>
                  <Chip
                    size="small"
                    label={test.status}
                    color="primary"
                    deleteIcon={<DoneIcon />}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }

  return null;
};

export default PrevTestDetails;
