import React, {
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import theme from "../../assets/v3/theme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import AlertComponent from "../../mycomponents/AlertComponent";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "info",
    customActions: [],
    autoClose: false,
    duration: 3000,
    showCloseIcon: false,
    actionsPosition: "flex-end",
    btnText: "Close",
  });

  const openSnackbar = useCallback(
    (message, severity = "info", snack = true, custom = {}) => {
      if (snack) {
        setSnackbar({ open: true, message, severity });
      } else {
        const {
          customActions = [],
          autoClose = false,
          duration = 3000,
          showCloseIcon = false,
          actionsPosition = "flex-end",
          btnText = "Close",
          ...rest
        } = custom;

        setAlert({
          open: true,
          message,
          alertType: severity,
          customActions,
          autoClose,
          duration,
          showCloseIcon,
          actionsPosition,
          btnText,
          ...rest,
        });
      }
    },
    []
  );

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const closeAlert = (trigger = "none") => {
    setAlert((prev) => ({ ...prev, open: false }));
    const check1 = alert.onClose && alert.autoClose;
    const check2 = alert.triggerType === "all" || alert.triggerType === trigger;
    if (check1 && check2) {
      alert.onClose();
    }
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <ThemeProvider theme={theme}>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <MuiAlert
            onClose={closeSnackbar}
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>

        <AlertComponent
          open={alert.open}
          closeAlert={closeAlert}
          ifThemeRequired={false}
          newTheme
          autoClose={alert.autoClose}
          duration={alert.duration}
          showCloseIcon={alert.showCloseIcon}
          actionsPosition={alert.actionsPosition}
          btnText={alert.btnText}
          alertType={alert.alertType}
          customActions={alert.customActions}
        >
          {alert.message}
        </AlertComponent>
      </ThemeProvider>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
