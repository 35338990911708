// React and Hooks
import React, { useState, useRef, useCallback, useEffect } from "react";

// Material-UI Core Components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

// Material-UI Icons
import { Close, CloudUpload } from "@material-ui/icons";

// helpers
import CropImage from "../../helpers/CropImage";

// Custom Hooks
import useFetch from "../../hooks/useFetch";
import { useDialog } from "../../context/dialog";

// Styles Hook
const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    position: "relative",
    display: "block",
    "& *": {
      boxSizing: "border-box",
    },
    width: "100%",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  hiddenInput: {
    display: "none",
  },
  visibleInput: {
    width: "100%",
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
  },
  previewContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  previewItem: {
    position: "relative",
    width: 100,
    height: 100,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  removeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "rgba(0,0,0,0.5)",
    color: theme.palette.common.white,
    padding: theme.spacing(0.5),

    "&:hover": {
      background: "rgba(0,0,0,0.7)",
    },
  },
}));

// Custom Hook for File Upload
const useFileUpload = (options = {}) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const { openDialog } = useDialog();

  const defaultOptions = {
    multiple: false,
    maxFiles: 5,
    accept: "image/*",
    maxSize: 5 * 1024 * 1024, // 5MB
  };

  const mergedOptions = { ...defaultOptions, ...options };

  const handleFileChange = useCallback(
    (event) => {
      const newFiles = Array.from(event.target.files);

      const validFiles = newFiles.filter((file) => {
        // Size validation
        if (file.size > mergedOptions.maxSize) {
          alert(
            `File ${
              file.name
            } is too large. Max size is ${mergedOptions.maxSize /
              1024 /
              1024}MB`
          );
          return false;
        }

        // Dimension validation
        if (mergedOptions.width && mergedOptions.height) {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (
              img.width !== mergedOptions.width ||
              img.height !== mergedOptions.height
            ) {
              alert(
                `File ${file.name} does not match the required dimensions of ${
                  mergedOptions.width
                }x${mergedOptions.height}px`
              );
              return false;
            }
          };
        }

        return true;
      });

      // Apply max files limit
      const processedFiles = mergedOptions.multiple
        ? validFiles.slice(0, mergedOptions.maxFiles)
        : [validFiles[0]];

      // Create preview URLs
      const filesWithPreview = processedFiles
        .filter((file) => !!file)
        .map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }));

      setFiles((prev) => {
        // If not multiple, replace files
        return mergedOptions.multiple
          ? [...prev, ...filesWithPreview].slice(0, mergedOptions.maxFiles)
          : filesWithPreview;
      });

      openDialog("CROP_IMAGE", {
        photoUrl: filesWithPreview[0].preview,
        setPhotoUrl: (url, file) => {
          setFiles((prev) => {
            const updatedFiles = [...prev];
            updatedFiles[0].preview = url;
            updatedFiles[0].file = file;
            return updatedFiles;
          });
        },
      });
    },
    [mergedOptions]
  );

  const removeFile = useCallback((indexToRemove) => {
    setFiles((prev) => prev.filter((_, index) => index !== indexToRemove));
    if (fileInputRef.current) {
      const dt = new DataTransfer();
      const input = fileInputRef.current;
      const { files } = input;
      for (let i = 0; i < files.length; i++) {
        if (i !== indexToRemove) {
          dt.items.add(files[i]);
        }
      }
      input.files = dt.files;
    }
  }, []);

  const clearFiles = useCallback(() => {
    setFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, []);

  return {
    files,
    fileInputRef,
    handleFileChange,
    removeFile,
    clearFiles,
  };
};

// Main File Upload Component
const FileUploader = ({
  label,
  options = {},
  handleUpload,
  onFileUploadSuccess,
  description,
  info,
}) => {
  const classes = useStyles();
  const {
    files,
    fileInputRef,
    handleFileChange,
    removeFile,
    clearFiles,
  } = useFileUpload(options);
  const { dialogName, dialogData, closeDialog } = useDialog();

  const [l2, e2, d2, f2, r2] = useFetch(handleUpload);

  const dData = dialogData ? dialogData : {};

  const handleFileUpload = async () => {
    if (files.length > 0) {
      f2(files);
    }
  };

  useEffect(() => {
    if (d2) {
      if (d2.data.status === "SUCCESS") {
        onFileUploadSuccess(d2);
        clearFiles();
      } else {
        alert("Failed to upload file");
      }
    }
    if (e2) {
      console.error("Failed to upload file:", e2);
      alert("Failed to upload file");
    }

    return () => {
      r2();
    };
  }, [d2, e2]);

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <div className={classes.root}>
        <div className={classes.labelContainer}>
          {label && <Typography variant="subtitle1">{label}</Typography>}
        </div>

        <div className={classes.inputContainer}>
          {description && (
            <Typography variant="body1" color="secondary">
              {description}
            </Typography>
          )}
          <input
            ref={fileInputRef}
            type="file"
            className={classes.hiddenInput}
            onChange={handleFileChange}
            multiple={options.multiple}
            accept={options.accept}
          />
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<CloudUpload />}
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
            disabled={l2}
          >
            {files.length > 0
              ? `${files.length} file selected `
              : "Choose File"}
          </Button>
          {info && <Typography variant="body2">{info}</Typography>}
        </div>

        {files.length > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleFileUpload}
            fullWidth
            disabled={l2}
          >
            {l2 ? "Uploading..." : "Upload"}
          </Button>
        )}

        {files.length > 0 && (
          <Box marginBottom={1}>
            <Box
              display="flex"
              gap={1}
              sx={{ position: "relative" }}
              pt={2}
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={files[0].preview}
                alt={`preview-${0}`}
                width="200"
                height="200"
              />
              <IconButton
                size="small"
                onClick={() => removeFile(0)}
                className={classes.removeButton}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        )}
      </div>

      {dialogName === "CROP_IMAGE" ? (
        <CropImage
          photoURL={dData.photoUrl}
          setPhotoURL={dData.setPhotoUrl}
          open={dialogName === "CROP_IMAGE"}
          closeDialog={closeDialog}
          clear={clearFiles}
          clearOnClose={true}
        />
      ) : null}
    </Container>
  );
};

export default FileUploader;
