import React, { useState } from "react";
import Cropper from "react-easy-crop";
// mui
import Cancel from "@material-ui/icons/Close";
import CropIcon from "@material-ui/icons/Crop";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// assets
import colors from "../../assets/v3/base/colors";
// hooks
import { useLoading } from "../../context/loading";
import { useSnackbar } from "../../context/snackbar";
// utils
import getCroppedImg from "./utils";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    background: "#333",
    position: "relative",
    height: 400,
    padding: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  heading: {
    padding: theme.spacing(1.25),
    fontWeight: "bold",
  },
  slider: {
    color: colors.newUi.info,
    "& .MuiSlider-thumb": {
      backgroundColor: colors.newUi.info,
    },
    "& .MuiSlider-track": {
      backgroundColor: colors.newUi.info,
    },
    "& .MuiSlider-rail": {
      backgroundColor: colors.newUi.info,
    },
  },
  cropButton: {
    backgroundColor: colors.newUi.info,
    color: "#fff",
    "&:hover": {
      backgroundColor: colors.newUi.info,
    },
  },
  cancelButton: {
    borderColor: colors.newUi.info,
    color: colors.newUi.info,
    "&:hover": {
      borderColor: colors.newUi.info,
      color: colors.newUi.info,
    },
  },
}));

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};

const CropImage = ({
  photoURL,
  setPhotoURL,
  open,
  closeDialog,
  clear,
  clearOnClose,
}) => {
  const classes = useStyles();
  const { showLoading, hideLoading } = useLoading();
  const { openSnackbar } = useSnackbar();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    showLoading(true);
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setPhotoURL(url, file);
      closeDialog();
    } catch (error) {
      openSnackbar(error.message, "error");
      console.log(error);
    }
    hideLoading(false);
  };
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <Typography variant="h5" className={classes.heading}>
        Crop Image
      </Typography>
      <DialogContent dividers className={classes.dialogContent}>
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box>
              <Typography variant="body2">
                Zoom: <b>{zoomPercent(zoom)}</b>
              </Typography>
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={zoomPercent}
                min={1}
                max={3}
                step={0.02}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
                className={classes.slider}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="body2">
                Rotation: <b>{rotation + "°"}</b>
              </Typography>
              <Slider
                valueLabelDisplay="auto"
                min={0}
                max={360}
                value={rotation}
                onChange={(e, rotation) => setRotation(rotation)}
                className={classes.slider}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                startIcon={<Cancel />}
                onClick={() => {
                  closeDialog();
                  if (clearOnClose) {
                    clear();
                  }
                }}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                startIcon={<CropIcon />}
                onClick={cropImage}
                className={classes.cropButton}
              >
                Crop
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CropImage;
